import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { TestIDProps } from "@utils/typeUtils"

function ChatChannelApp({ testid }: TestIDProps) {
  const { setOpenModalState } = useActiveAppModal()

  return (
    <AppListItem
      testid={`${testid}.chat_channel`}
      title={"Channel"}
      description={"Create a conversation channel. Great for discussion topics."}
      icon={"discussion"}
      onClick={handleOpenAddChannelModal}
    />
  )

  function handleOpenAddChannelModal() {
    setOpenModalState({
      kind: "chat_channel",
    })
  }
}

export default ChatChannelApp
