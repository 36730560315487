import AppSubmitFormSection from "@/apps/list/form-sections/AppSubmitFormSection"
import LegacyAppIconAndTitleFormSection from "@/apps/list/form-sections/LegacyAppIconAndTitleFormSection"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { ProductAppKind } from "@/apps/util/hooks/__generated__/useAddAppFormStoreMutation.graphql"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoSwitch, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  appId?: GlobalID
  kind: ProductAppKind
}

function GenericAppSettingsModalContent({ appId, kind }: Props) {
  const { closeModal } = useActiveAppModal()
  const form = useEditAppFormStore(appId)

  return (
    <Form
      testid={"AppSettingsModalForm"}
      onSubmit={handleEditApp}
      buttons={
        <AppSubmitFormSection
          form={form}
          mode={"edit"}
          isSubmitDisabled={false}
          onClose={closeModal}
        />
      }
    >
      <LegacyAppIconAndTitleFormSection form={form} />
      {kind === "curriculum" && (
        <DiscoSwitch
          label={
            <DiscoText variant={"body-sm"}>{"Show Completed Users in Modules"}</DiscoText>
          }
          checked={form.state.showCompletedUsers ?? true}
          onChange={(isOn) => {
            form.state.showCompletedUsers = isOn
          }}
          disabled={form.isSubmitting}
        />
      )}
    </Form>
  )

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...changedState
      } = form.changedState
      const { didSave } = await form.submit({
        id: form.state.id,
        ...changedState,
        badge: form.state.badge,
      })
      if (!didSave) return
      displaySuccessToast({
        message: "Updated app!",
        testid: "AddApp.success-toast",
      })
      closeModal()
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

export default observer(GenericAppSettingsModalContent)
