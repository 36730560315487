/**
 * @generated SignedSource<<97d29c6fdfc99a757a45c1cb3ad70134>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SlackProductConnectionSlackChannelType = "private" | "public" | "%future added value";
export type CreateSlackProductConnectionInput = {
  organizationId: string;
  channelId?: string | null;
  productId: string;
  inviteURL?: string | null;
};
export type SlackProductConnectionSettingsModal_createMutation$variables = {
  input: CreateSlackProductConnectionInput;
};
export type SlackProductConnectionSettingsModal_createMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly inviteURL: string | null;
      readonly channel: {
        readonly id: string;
        readonly name: string;
        readonly type: SlackProductConnectionSlackChannelType;
      } | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type SlackProductConnectionSettingsModal_createMutation = {
  variables: SlackProductConnectionSettingsModal_createMutation$variables;
  response: SlackProductConnectionSettingsModal_createMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSlackProductConnectionResponse",
    "kind": "LinkedField",
    "name": "createSlackProductConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SlackProductConnection",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SlackChannel",
            "kind": "LinkedField",
            "name": "channel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SlackProductConnectionSettingsModal_createMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SlackProductConnectionSettingsModal_createMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ac447248875fe3aaab467b1d3fbd2d45",
    "id": null,
    "metadata": {},
    "name": "SlackProductConnectionSettingsModal_createMutation",
    "operationKind": "mutation",
    "text": "mutation SlackProductConnectionSettingsModal_createMutation(\n  $input: CreateSlackProductConnectionInput!\n) {\n  response: createSlackProductConnection(input: $input) {\n    node {\n      id\n      inviteURL\n      channel {\n        id\n        name\n        type\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "10b91360d1e542c4215cd32696900a8e";

export default node;
