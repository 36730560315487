import { ChatChannelMemberMultiSelect } from "@/apps/list/app/chat/form/ChatChannelSettingsForm"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import OrganizationMemberMultiSelect from "@/organization/member/common/OrganizationMemberMultiSelect"
import ProductMemberMultiSelect, {
  MemberMultiSelect,
} from "@/product/member/common/ProductMemberMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<{ memberships: ChatChannelMemberMultiSelect[] }>
  channelAdmins: { id: GlobalID; fullName: string }[]
  productId?: GlobalID | null
}

function ChatChannelFormMembersSection({ form, productId, channelAdmins }: Props) {
  const { authUser } = useAuthUser({ required: true })

  const labels = useLabels()
  const membersLabel = labels[productId ? "product_member" : "organization_member"]
  const adminsLabel = productId ? labels.product_admin.plural : "Admins"
  const manualAddedMembers = form.state.memberships.filter(
    (m) => m.joinMethod === "manual"
  )
  const groupAddedMembers = form.state.memberships.filter((m) => m.joinMethod === "group")
  const hideUserIds = [
    authUser.id,
    ...channelAdmins.map((ca) => ca.id),
    ...groupAddedMembers.map((ga) => ga.memberId),
  ]

  return (
    <>
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {`Add ${membersLabel.plural}`}
          </DiscoText>
        }
        marginBottom={0}
      >
        {productId ? (
          <ProductMemberMultiSelect
            testid={"ChatChannelFormFields.member-select"}
            productId={productId}
            values={manualAddedMembers.map((m) => m.id)}
            onChange={handleChangeMembership}
            hideUserIds={hideUserIds}
            placeholder={`Select ${membersLabel.plural}`}
          />
        ) : (
          <OrganizationMemberMultiSelect
            testid={"ChatChannelFormFields.member-select"}
            values={manualAddedMembers.map((m) => m.id)}
            onChange={handleChangeMembership}
            hideUserIds={hideUserIds}
            placeholder={`Select ${membersLabel.plural}`}
          />
        )}
      </DiscoFormControl>
      {channelAdmins.length > 0 && (
        <DiscoFormControl
          label={
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {adminsLabel}
            </DiscoText>
          }
          tooltip={`${adminsLabel} can't remove other ${adminsLabel} from private chat channels.`}
          marginTop={2.5}
          marginBottom={0}
        >
          <DiscoInput
            value={channelAdmins
              .map((ca) => (ca.id === authUser.id ? "You" : ca.fullName))
              .join(", ")}
            readOnly
            multiline
          />
        </DiscoFormControl>
      )}
    </>
  )

  function handleChangeMembership(memberships: MemberMultiSelect[]) {
    form.state.memberships.replace(
      memberships.map(
        (m) => ({ ...m, joinMethod: "manual" } as ChatChannelMemberMultiSelect)
      )
    )
  }
}

export default observer(ChatChannelFormMembersSection)
