/**
 * @generated SignedSource<<e3f2d8c16401f35fbcb946ca2120818d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedAppSettingsButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FeedAppSetupModalFragment">;
  readonly " $fragmentType": "FeedAppSettingsButtonFragment";
};
export type FeedAppSettingsButtonFragment$key = {
  readonly " $data"?: FeedAppSettingsButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedAppSettingsButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedAppSettingsButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedAppSetupModalFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "ab9a43fc10bc96db9f3701533a6f87fa";

export default node;
