import {
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { DiscoAlert, DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  /** This component uses the old method of creating apps.
   * For the new pattern, look at AppIconAndTitleFormSection.tsx
   */
  form: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
  placeholder?: string
  warningText?: string
}

function AppEmbedCodeFormSection({ form, placeholder, warningText }: Props) {
  return (
    <>
      {warningText && (
        <DiscoAlert
          severity={"warning"}
          message={
            <DiscoText variant={"body-sm"} component={"span"}>
              {warningText}
            </DiscoText>
          }
          marginBottom={1}
        />
      )}
      <DiscoFormControl
        label={<DiscoText variant={"body-sm"}>{"Embed code"}</DiscoText>}
        errorMessages={form?.errorsByField.embedCode}
      >
        <DiscoInput
          data-testid={"AppEmbedCodeFormSection.Input.app-code"}
          name={"code"}
          multiline
          value={form?.state.embedCode}
          onChange={codeChange}
          minHeight={"120px"}
          placeholder={placeholder || "Paste in embed code"}
        />
      </DiscoFormControl>
    </>
  )

  function codeChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    form.state.embedCode = e.target.value
  }
}

export default observer(AppEmbedCodeFormSection)
