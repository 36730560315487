import EventsSettingsForm from "@/apps/list/app/events/EventsSettingsForm"
import { EventsAppSetupModalFragment$key } from "@/apps/list/app/events/__generated__/EventsAppSetupModalFragment.graphql"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  appKey?: EventsAppSetupModalFragment$key
}

function EventsAppSetupModal({ testid, appKey }: Props) {
  const { openModalState, closeModal, setOpenModalState } = useActiveAppModal()

  const app = useFragment<EventsAppSetupModalFragment$key>(
    graphql`
      fragment EventsAppSetupModalFragment on ProductApp {
        id
      }
    `,
    appKey || null
  )

  return (
    <SetupAppModal
      testid={`${testid}.feed`}
      isModalOpen={openModalState?.kind === "organization_events"}
      title={"Event App Setup"}
      body={
        <EventsSettingsForm
          testid={`${testid}.events`}
          appId={app?.id}
          onClose={closeModal}
          onBack={app ? closeModal : () => setOpenModalState({ kind: "add-app" })}
        />
      }
    />
  )
}

export default observer(EventsAppSetupModal)
