import MembersSettingsForm from "@/apps/list/app/members/modal/MembersSettingsForm"
import { MembersSettingsModalFragment$key } from "@/apps/list/app/members/modal/__generated__/MembersSettingsModalFragment.graphql"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { useLabel } from "@/core/context/LabelsContext"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  appKey: MembersSettingsModalFragment$key
}

function MembersAppSetupModal({ testid, appKey }: Props) {
  const { openModalState, closeModal } = useActiveAppModal()
  const memberLabel = useLabel("product_member")

  const app = useFragment<MembersSettingsModalFragment$key>(
    graphql`
      fragment MembersSettingsModalFragment on ProductApp {
        id
      }
    `,
    appKey
  )

  return (
    <SetupAppModal
      testid={`${testid}.feed`}
      isModalOpen={openModalState?.kind === "members"}
      title={`${memberLabel.plural} App Setup`}
      description={`Edit settings on your ${memberLabel.plural} app.`}
      body={
        <MembersSettingsForm
          testid={`${testid}.members`}
          appId={app.id}
          onClose={closeModal}
        />
      }
    />
  )
}

export default observer(MembersAppSetupModal)
