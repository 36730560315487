/**
 * @generated SignedSource<<1298b80437cedca6aafe4142eaa25e46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteChatChannelAppButtonMutation$variables = {
  id: string;
  appConnections: ReadonlyArray<string>;
  channelConnections: ReadonlyArray<string>;
};
export type DeleteChatChannelAppButtonMutation$data = {
  readonly response: {
    readonly deletedChatChannelAppId: string | null;
    readonly deletedChatChannelId: string | null;
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"StreamChatContext_ChannelsFragment">;
    } | null;
  };
};
export type DeleteChatChannelAppButtonMutation = {
  variables: DeleteChatChannelAppButtonMutation$variables;
  response: DeleteChatChannelAppButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appConnections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channelConnections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedChatChannelAppId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedChatChannelId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteChatChannelAppButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v3/*: any*/),
        "concreteType": "DeleteChatChannelAppResponse",
        "kind": "LinkedField",
        "name": "deleteChatChannelApp",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StreamChatContext_ChannelsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteChatChannelAppButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v3/*: any*/),
        "concreteType": "DeleteChatChannelAppResponse",
        "kind": "LinkedField",
        "name": "deleteChatChannelApp",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedChatChannelAppId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "appConnections"
              }
            ]
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedChatChannelId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "channelConnections"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeProductLevel",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "kinds",
                    "value": [
                      "default",
                      "custom",
                      "direct_message"
                    ]
                  }
                ],
                "concreteType": "ChatChannelNodeConnection",
                "kind": "LinkedField",
                "name": "chatChannels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatChannelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChatChannel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalChannelId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductMembership",
                                "kind": "LinkedField",
                                "name": "viewerMembership",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "chatChannels(includeProductLevel:true,kinds:[\"default\",\"custom\",\"direct_message\"])"
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a19711d545e2544dc50459d449408ca",
    "id": null,
    "metadata": {},
    "name": "DeleteChatChannelAppButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteChatChannelAppButtonMutation(\n  $id: ID!\n) {\n  response: deleteChatChannelApp(id: $id) {\n    deletedChatChannelAppId\n    deletedChatChannelId\n    organization {\n      ...StreamChatContext_ChannelsFragment\n      id\n    }\n  }\n}\n\nfragment StreamChatContext_ChannelsFragment on Organization {\n  chatChannels(kinds: [default, custom, direct_message], includeProductLevel: true) {\n    edges {\n      node {\n        id\n        productId\n        externalChannelId\n        kind\n        appId\n        product {\n          viewerMembership {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f8ddddf7daa14e6d3541f3e713e88a6";

export default node;
