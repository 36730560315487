import { useAppLevel } from "@/apps/util/appLevelContext"
import { useLabels } from "@/core/context/LabelsContext"
import OrganizationMemberMultiSelect from "@/organization/member/common/OrganizationMemberMultiSelect"
import { MemberMultiSelect } from "@/product/member/common/ProductMemberMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoFormControl, DiscoFormControlProps, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends Pick<DiscoFormControlProps, "errorMessages">, TestIDProps {
  onChange: (memberships: MemberMultiSelect[]) => void
  membershipIds: GlobalID[]
  productId?: GlobalID | null
  hideUserIds?: GlobalID[]
}

function AppFormMembersSection({
  testid = "AppFormMembersSection",
  membershipIds,
  productId,
  onChange,
  hideUserIds,
  errorMessages,
}: Props) {
  const labels = useLabels()
  const membersLabel = labels[productId ? "product_member" : "organization_member"]
  const { product } = useAppLevel()

  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {`Add ${membersLabel.plural}`}
        </DiscoText>
      }
      marginBottom={0}
      errorMessages={errorMessages}
    >
      <OrganizationMemberMultiSelect
        testid={`${testid}.member-select`}
        values={membershipIds}
        onChange={onChange}
        hideUserIds={hideUserIds}
        placeholder={`Select ${membersLabel.plural}`}
        productIds={product ? [product.id] : null}
      />
    </DiscoFormControl>
  )
}

export default observer(AppFormMembersSection)
