import {
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { DiscoFormControl, DiscoLinkInput, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  /** This component uses the old method of creating apps.
   * For the new pattern, look at AppIconAndTitleFormSection.tsx
   */
  form: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
  placeholder?: string
  label?: string
}

function AppEmbedUrlFormSection({ form, label, placeholder }: Props) {
  return (
    <DiscoFormControl
      label={<DiscoText variant={"body-sm"}>{label || "URL"}</DiscoText>}
      errorMessages={form?.errorsByField.customAppUrl}
    >
      <DiscoLinkInput
        data-testid={"AppUrlFormSection.Input.app-url"}
        placeholder={placeholder}
        name={"url"}
        value={form?.state.customAppUrl}
        onChange={(val) => (form.state.customAppUrl = val)}
      />
    </DiscoFormControl>
  )
}

export default observer(AppEmbedUrlFormSection)
