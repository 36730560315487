import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { observer } from "mobx-react-lite"
import AppSettingsForm from "../../common/settings/AppSettingsForm"

interface Props {
  testid: string
  appId?: GlobalID
  onClose: () => void
}

function MembersSettingsForm({ testid, appId, onClose }: Props) {
  const editForm = useEditAppFormStore(appId)

  return (
    <AppSettingsForm
      testid={testid}
      form={editForm}
      mode={"edit"}
      hideIconAndTitle
      onSubmit={handleEditApp}
      onClose={onClose}
    />
  )

  async function handleEditApp() {
    try {
      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        visibility: editForm.changedState.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      })

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Members app updated!",
        testid: "EditMembersAppForm.success-toast",
      })

      onClose()
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

export default observer(MembersSettingsForm)
