import {
  AppIcons,
  AppSpecialIconKind,
} from "@/apps/list/form-sections/LegacyAppIconAndTitleFormSection"
import { AppIconKind } from "@/apps/util/activeAppModalContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconKinds } from "@disco-ui"

interface Props {
  kind: AppIconKind
  height?: number
  width?: number
}

function AppIcon({ kind, height = 24, width = 24 }: Props) {
  const classes = useStyles()
  return (
    <span className={classes.container}>
      {AppIcons[kind as AppSpecialIconKind] || (
        <DiscoIcon height={height} width={width} icon={kind as DiscoIconKinds} />
      )}
    </span>
  )
}

const useStyles = makeUseStyles({
  container: {
    display: "inherit",
  },
})

export default AppIcon
