/**
 * @generated SignedSource<<f0348f2e0cde1505a844cb865cdccc3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionAppSettingsModalFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "CollectionAppSettingsModalFragment";
};
export type CollectionAppSettingsModalFragment$key = {
  readonly " $data"?: CollectionAppSettingsModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionAppSettingsModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionAppSettingsModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "d4ca87d05bff21c60fc5d6a0aceda601";

export default node;
