import { EventsAppSettingsButtonFragment$key } from "@/apps/list/app/events/__generated__/EventsAppSettingsButtonFragment.graphql"
import EventsAppSetupModal from "@/apps/list/app/events/EventsAppSetupModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { OverridableDiscoButton } from "@disco-ui/button/OverridableDiscoButton"
import { graphql, useFragment } from "react-relay"

interface EventsAppSettingsButtonProps {
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string

  appKey: EventsAppSettingsButtonFragment$key
}

const TEST_ID = "EventsAppSettings"

export default function EventsAppSettingsButton({
  appKey,
  children,
}: EventsAppSettingsButtonProps) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<EventsAppSettingsButtonFragment$key>(
    graphql`
      fragment EventsAppSettingsButtonFragment on ProductApp {
        id
        ...EventsAppSetupModalFragment
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} testid={TEST_ID}>
        {children}
      </OverridableDiscoButton>

      {openModalState?.id === app.id && (
        <EventsAppSetupModal testid={TEST_ID} appKey={app} />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({
      kind: "organization_events",
      id: app.id,
    })
  }
}
