import { LinkAppSetupModalFragment$key } from "@/apps/list/app/link/modal/__generated__/LinkAppSetupModalFragment.graphql"
import LinkAppSetupModalContent from "@/apps/list/app/link/modal/LinkAppSetupModalContent"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import { useLabel } from "@/core/context/LabelsContext"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  testid: string
  appKey?: LinkAppSetupModalFragment$key
}

function LinkAppSetupModal({ testid, appKey }: Props) {
  const { openModalState } = useActiveAppModal()
  const { navFolderId, product } = useAppLevel()
  const productLabel = useLabel("admin_experience")
  const memberLabel = useLabel("admin_member")

  const app = useFragment<LinkAppSetupModalFragment$key>(
    graphql`
      fragment LinkAppSetupModalFragment on ProductApp {
        id
        kind
      }
    `,
    appKey || null
  )

  const createForm = useAddAppFormStore({
    kind: "link",
    customAppTitle: "Custom Link",
    badge: {
      kind: "icon",
      icon: APP_KIND_ICON.link,
      color: "#ffffff00",
    },
    navFolderId,
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
  })

  const isProductLink = (app?.kind ?? createForm.state.kind) === "product_link"
  // Don't allow switching kinds for existing apps, or at the product level
  const showTabs = !app && !product
  const description = isProductLink
    ? `Link to one of your ${productLabel.plural}. Only ${memberLabel.plural} who are registered for the ${productLabel.singular} will see it.`
    : "This external link will be opened in a new browser tab."

  return (
    <SetupAppModal
      testid={`${testid}.link`}
      isModalOpen={
        openModalState?.kind === "link" || openModalState?.kind === "product_link"
      }
      title={"Link"}
      // Show the description under the tabs instead of above them if present
      description={showTabs ? undefined : description}
      subtabs={
        showTabs && (
          <DiscoTabs
            testid={`${testid}.link.tabs`}
            routes={[
              {
                testid: "custom",
                onClick: () => (createForm.state.kind = "link"),
                label: "Custom Link",
                active: createForm.state.kind === "link",
              },
              {
                testid: "product",
                onClick: () => (createForm.state.kind = "product_link"),
                label: `${productLabel.singular} Link`,
                active: createForm.state.kind === "product_link",
              },
            ]}
          />
        )
      }
      body={
        <LinkAppSetupModalContent
          testid={testid}
          createForm={createForm}
          appId={app?.id}
          isProductLink={isProductLink}
          description={description}
        />
      }
    />
  )
}

export default observer(LinkAppSetupModal)
