import ContentAppSetupModalContent, {
  CreateContentAppStateData,
} from "@/apps/list/app/content/modal/ContentAppSetupModalContent"
import { ContentAppSetupModalFragment$key } from "@/apps/list/app/content/modal/__generated__/ContentAppSetupModalFragment.graphql"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, ContentAppKind } from "@/apps/util/appConstants"
import {
  INTEGRATION_EMBED_PLACEHOLDER,
  INTEGRATION_URL_PLACEHOLDER,
} from "@/apps/util/appUtil"
import { DEFAULT_SCORM_TEXT } from "@components/editor/plugins/embeds/scorm/UploadScormFileDropzone"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  appKey?: ContentAppSetupModalFragment$key
}

function ContentAppSetupModal({ testid, appKey }: Props) {
  const { openModalState } = useActiveAppModal()

  const app = useFragment<ContentAppSetupModalFragment$key>(
    graphql`
      fragment ContentAppSetupModalFragment on ProductApp {
        id
      }
    `,
    appKey || null
  )

  const defaultCreateAppModalState = openModalState?.contentAppKind
    ? getDefaultAppModalStateData(openModalState?.contentAppKind)
    : undefined

  const [step, setStep] = useState<"setup-app" | "select-content">("setup-app")

  return (
    <SetupAppModal
      testid={`${testid}.content`}
      onBack={step === "select-content" ? () => setStep("setup-app") : undefined}
      isModalOpen={openModalState?.kind === "content"}
      title={
        defaultCreateAppModalState?.name
          ? `${defaultCreateAppModalState?.name} App Setup`
          : `App Setup`
      }
      description={getModalDescription()}
      body={
        <ContentAppSetupModalContent
          appId={app?.id}
          createStateData={defaultCreateAppModalState}
        />
      }
      buttons={step === "setup-app"}
    />
  )

  function getModalDescription() {
    if (step === "select-content")
      return "Select the template or content to use for your app"
    if (defaultCreateAppModalState?.description)
      return defaultCreateAppModalState.description
    return "Customize the icon and title to your preference."
  }

  function getDefaultAppModalStateData(kind: ContentAppKind): CreateContentAppStateData {
    switch (kind) {
      case "page":
        return {
          name: "Content",
          description: "Add content with text, images, videos and embeds.",
          icon: APP_KIND_ICON.page,
        }
      case "custom_embed":
        return {
          name: "Embed",
          description: "Input your custom HTML embed code.",
          icon: APP_KIND_ICON.custom_embed,
          placeholder: INTEGRATION_EMBED_PLACEHOLDER.custom_embed,
          warningText:
            "Embeds may not display properly if your source does not officially support embedding (e.g. Notion).",
        }
      case "discord":
        return {
          name: "Discord",
          icon: APP_KIND_ICON.discord,
          placeholder: INTEGRATION_EMBED_PLACEHOLDER.discord,
        }
      case "airtable":
        return {
          name: "Airtable",
          icon: APP_KIND_ICON.airtable,
          placeholder: INTEGRATION_URL_PLACEHOLDER.airtable,
        }
      case "google_doc":
        return {
          name: "Google Doc",
          icon: APP_KIND_ICON.google_doc,
          placeholder: INTEGRATION_URL_PLACEHOLDER.google_docs,
        }
      case "google_form":
        return {
          name: "Google Form",
          icon: APP_KIND_ICON.google_form,
          placeholder: INTEGRATION_URL_PLACEHOLDER.google_forms,
        }
      case "typeform":
        return {
          name: "Typeform",
          icon: APP_KIND_ICON.typeform,
          placeholder: INTEGRATION_URL_PLACEHOLDER.typeform,
        }
      case "whereby":
        return {
          name: "Whereby",
          icon: APP_KIND_ICON.whereby,
          placeholder: INTEGRATION_EMBED_PLACEHOLDER.whereby,
        }
      case "figma":
        return {
          name: "Figma",
          icon: APP_KIND_ICON.figma,
          placeholder: INTEGRATION_URL_PLACEHOLDER.figma,
        }
      case "miro":
        return {
          name: "Miro",
          icon: APP_KIND_ICON.miro,
          placeholder: INTEGRATION_EMBED_PLACEHOLDER.miro,
        }
      case "scorm":
        return {
          name: "SCORM",
          description: DEFAULT_SCORM_TEXT.uploadDescription,
          icon: APP_KIND_ICON.scorm,
        }
      default:
        throw new Error(`Unkown ContentAppKind: ${kind}`)
    }
  }
}

export default ContentAppSetupModal
