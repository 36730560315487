/**
 * @generated SignedSource<<3239141083a0c57802a4e5b8240e281d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GenericAppSettingsModalFragment$data = {
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly " $fragmentType": "GenericAppSettingsModalFragment";
};
export type GenericAppSettingsModalFragment$key = {
  readonly " $data"?: GenericAppSettingsModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GenericAppSettingsModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenericAppSettingsModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "12c7c152aa19b1115db7eae29b83c5c8";

export default node;
