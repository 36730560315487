import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import { ChatChannelSettingsDrawerContentQuery } from "@/apps/list/app/chat/__generated__/ChatChannelSettingsDrawerContentQuery.graphql"
import ChatChannelSettingsForm from "@/apps/list/app/chat/form/ChatChannelSettingsForm"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  appId: GlobalID
  onClose: () => void
}

function ChatChannelSettingsDrawerContent({ appId, onClose }: Props) {
  const classes = useStyles()

  const { app } = useLazyLoadQuery<ChatChannelSettingsDrawerContentQuery>(
    graphql`
      query ChatChannelSettingsDrawerContentQuery($id: ID!) {
        app: node(id: $id) {
          ... on ProductApp {
            customAppTitle
          }
        }
      }
    `,
    {
      id: appId,
    }
  )

  return (
    <>
      <div className={classes.drawerTitleContainer}>
        <DiscoText variant={"body-lg-600"} marginBottom={2.5}>
          {"Channel Settings"}
        </DiscoText>
        {app?.customAppTitle && (
          <DiscoText variant={"body-lg-600"} color={"groovy.grey.500"}>
            {`/ ${app.customAppTitle}`}
          </DiscoText>
        )}
      </div>

      <ChatChannelSettingsForm
        testid={"ChatChannelSettingsDrawer"}
        appId={appId}
        onClose={onClose}
      />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawerTitleContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

function ChatChannelSettingsDrawerContentSkeleton() {
  return (
    <div>
      <DiscoTextSkeleton width={150} />
      <DiscoTextSkeleton width={300} />
      <LabelFormFieldSkeleton />
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: ChatChannelSettingsDrawerContent,
  skeleton: ChatChannelSettingsDrawerContentSkeleton,
})
