import { ChatChannelSettingsModalButtonFragment$key } from "@/apps/list/app/chat/button/__generated__/ChatChannelSettingsModalButtonFragment.graphql"
import ChatChannelSettingsModal from "@/apps/list/app/chat/ChatChannelSettingsModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { OverridableDiscoButton } from "@disco-ui/button/OverridableDiscoButton"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  appKey: ChatChannelSettingsModalButtonFragment$key
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string
}

function ChatChannelSettingsModalButton({ appKey, children }: Props) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<ChatChannelSettingsModalButtonFragment$key>(
    graphql`
      fragment ChatChannelSettingsModalButtonFragment on ProductApp {
        id
        productId
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>

      {openModalState?.id === app.id && (
        <ChatChannelSettingsModal
          appId={app.id}
          productLevel={Boolean(app.productId)}
          testid={"ChatChannelSettingsModalButton"}
        />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({ kind: "chat_channel", id: app.id })
  }
}

export default ChatChannelSettingsModalButton
