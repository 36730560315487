import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoAlert } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { generatePath, useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  appId?: GlobalID
  onClose: () => void
  onBack: () => void
}

function EventsSettingsForm({ testid, appId, onClose, onBack }: Props) {
  const { navFolderId, navSectionId } = useAppLevel()
  const history = useHistory()

  const createForm = useAddAppFormStore({
    kind: "organization_events",
    customAppTitle: "",
    navFolderId,
    navSectionId,
    visibility: "all",
    visibilityGroups: [],
    badge: {
      kind: "icon",
      icon: "calendar",
      color: "#ffffff00",
    },
  })
  const editForm = useEditAppFormStore(appId)

  const form = appId ? editForm : createForm

  return (
    <AppSettingsForm
      testid={testid}
      form={form}
      mode={appId ? "edit" : "add"}
      onSubmit={appId ? handleEditApp : handleAddApp}
      onClose={onBack}
      membersConfig={{ hide: true }}
      visibilityConfig={{
        // Disable if editing
        disable: Boolean(appId),
        alert: (
          <DiscoAlert
            marginBottom={1.5}
            severity={"warning"}
            // Was determined that changing event app visibility after creation
            // could have side effects on events within the app, and would require
            // intervention from the creator to update the individual events visiblity
            // Ref: https://www.figma.com/file/1TrLirIwM7iWLpX88Da6aX?type=design&node-id=3922-24455&mode=dev#773843314
            message={"This cannot be changed after the app is created."}
          />
        ),
      }}
    />
  )

  async function handleAddApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...state
      } = createForm.state

      const { didSave, response } = await createForm.submit(state, {
        connections: [],
        variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
      })

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: `${createForm.state.customAppTitle} created!`,
        testid: "NewEventsForm.success-toast",
      })

      onClose()

      redirectToEvents(response.node.id)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...changedState
      } = editForm.changedState
      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        ...changedState,
        badge: editForm.state.badge,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      })

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Events updated!",
        testid: "AddApp.success-toast",
      })

      onClose()

      redirectToEvents(response.node.id)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function redirectToEvents(id: GlobalID) {
    // Don't redirect if we're on the events page already
    if (location.pathname.includes(id)) return

    history.push(generatePath(ROUTE_NAMES.COMMUNITY.EVENTS.APP, { appId: id }))
  }
}

export default observer(EventsSettingsForm)
