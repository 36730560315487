import ContentApp from "@/apps/list/app/content/ContentApp"
import SlackProductApp from "@/apps/list/app/slack/SlackProductApp"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import useUnconnectedProductApps from "@/apps/util/hooks/useUnconnectedProductApps"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"

const TEST_ID = "IntegrationsAppsList"

function IntegrationAppsList() {
  const classes = useStyles()
  const { closeModal } = useActiveAppModal()
  const unconnectedProductApps = useUnconnectedProductApps()
  const activeOrganization = useActiveOrganization()!

  return (
    <div className={classes.container}>
      {unconnectedProductApps.includes("slack") &&
        activeOrganization.slackConnection?.version === 1 && (
          <SlackProductApp testid={TEST_ID} handleCloseModal={closeModal} />
        )}
      <ContentApp testid={`${TEST_ID}.${"discord"}`} kind={"discord"} />
      <ContentApp testid={`${TEST_ID}.${"airtable"}`} kind={"airtable"} />
      <ContentApp testid={`${TEST_ID}.${"google_doc"}`} kind={"google_doc"} />
      <ContentApp testid={`${TEST_ID}.${"google_form"}`} kind={"google_form"} />
      <ContentApp testid={`${TEST_ID}.${"typeform"}`} kind={"typeform"} />
      <ContentApp testid={`${TEST_ID}.${"whereby"}`} kind={"whereby"} />
      <ContentApp testid={`${TEST_ID}.${"figma"}`} kind={"figma"} />
      <ContentApp testid={`${TEST_ID}.${"miro"}`} kind={"miro"} />
      <ContentApp testid={`${TEST_ID}.${"scorm"}`} kind={"scorm"} entitlement={"scorm"} />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
    gap: theme.spacing(1.5, 2),
    "& svg": {
      height: "24px",
      width: "24px",
    },
  },
}))

export default IntegrationAppsList
