import AddAppButton from "@/apps/add-app-button/AddAppButton"
import { AppData } from "@/organization/common/sidebar/my-experiences-list/util/useRenderProductAppItem"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"

type Props = {
  folder: AppData
}

export default function NavFolderAddAppButton(props: Props) {
  const { folder } = props
  const permissions = usePermissions(folder.product)
  const classes = useStyles()
  if (!permissions.has("apps.manage")) return null

  return (
    <AddAppButton customTitle={folder.customAppTitle}>
      {({ onClick }) => {
        return (
          <DiscoTooltip content={"Add App"}>
            <DiscoIconButton
              className={classes.addIconButton}
              testid={"NavFolder.add-app"}
              onClick={(e) => {
                e.stopPropagation()
                onClick()
              }}
            >
              <DiscoIcon icon={"add"} />
            </DiscoIconButton>
          </DiscoTooltip>
        )
      }}
    </AddAppButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  addIconButton: {
    padding: theme.spacing(0.375),
    "&:hover": {
      background: "rgba(0,0,0,.1)",
    },
  },
}))
