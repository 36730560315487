import SlackProductConnectionSettingsModal from "@/apps/list/app/slack/modal/SlackProductConnectionSettingsModal"
import { SlackProductAppQuery } from "@/apps/list/app/slack/__generated__/SlackProductAppQuery.graphql"
import AppListItem, { AppListItemSkeleton } from "@/apps/list/AppListItem"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import useAddApp from "@/apps/util/hooks/useAddApp"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"

interface SlackProductAppProps {
  testid: string
  handleCloseModal: () => void
}

function SlackProductApp({ testid, handleCloseModal }: SlackProductAppProps) {
  const [isLoading, setIsLoading] = useState(false)
  const addApp = useAddApp()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const experienceLabel = useLabel("experience")
  const history = useHistory()

  const activeOrganization = useActiveOrganization()!

  const { node } = useLazyLoadQuery<SlackProductAppQuery>(
    graphql`
      query SlackProductAppQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Organization {
            hasSlackConnection
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "store-and-network" }
  )

  const TEST_ID = `${testid}.slack`

  return (
    <>
      <AppListItem
        testid={`${testid}.slack`}
        icon={APP_KIND_ICON.slack}
        title={"Slack"}
        onClick={handleAddSlackApp}
        isLoading={isLoading}
      />
      <SlackProductConnectionSettingsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={handleCreateProductApp}
      />
      <DiscoWarningModal
        variant={"warning"}
        testid={"SlackProductApp.warning-modal"}
        isOpen={isWarningModalOpen}
        onClose={closeWarningModal}
        title={"A Slack workspace is not connected to this community"}
        description={`You will be redirected to the community settings page where you can connect a Slack workspace. After doing so, please return to the desired ${experienceLabel.singular} to continue the process of connecting a Slack channel.`}
        customConfirmationButton={
          <DiscoButton
            width={"100%"}
            onClick={handleRedirectToCommunitySettings}
            color={"primary"}
            testid={`SlackProductApp.warning-modal.confirm-button`}
          >
            {"Take me to community settings"}
          </DiscoButton>
        }
        modalContentLabel={`Slack ${experienceLabel.singular} Dialog`}
      />
    </>
  )

  function handleAddSlackApp() {
    setIsLoading(true)

    // If Slack is connected, open modal
    if (node?.hasSlackConnection) {
      openModal()
    } else {
      // If Slack is not connected, open warning redirect modal
      openWarningModal()
    }
    setIsLoading(false)
  }

  async function handleCreateProductApp() {
    try {
      await addApp("slack", TEST_ID)
      handleCloseModal()
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function handleRedirectToCommunitySettings() {
    history.push(ROUTE_NAMES.ADMIN.INTEGRATIONS.LIST)
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function openWarningModal() {
    setIsWarningModalOpen(true)
  }

  function closeWarningModal() {
    setIsWarningModalOpen(false)
  }
}

const SlackProductAppSkeleton = () => {
  return <AppListItemSkeleton />
}

export default Relay.withSkeleton<SlackProductAppProps>({
  component: SlackProductApp,
  skeleton: SlackProductAppSkeleton,
})
