import useCopilotBot from "@/admin/integrations/copilot/util/useCopilotBot"
import ChatChannelPreviewBadge from "@/apps/list/app/chat/form/ChatChannelPreviewBadge"
import {
  ChatChannelMemberJoinMethod,
  ChatChannelSettingsFormQuery,
} from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsFormQuery.graphql"
import {
  ChatChannelSettingsForm_CreateMutation,
  ChatChannelVisibility,
  CreateChatChannelAppInput,
} from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsForm_CreateMutation.graphql"
import {
  ChatChannelSettingsForm_UpdateMutation,
  UpdateChatChannelAppInput,
} from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsForm_UpdateMutation.graphql"
import ChatChannelFormGroupsSection from "@/apps/list/app/chat/form/sections/ChatChannelFormGroupsSection"
import ChatChannelFormMembersSection from "@/apps/list/app/chat/form/sections/ChatChannelFormMembersSection"
import ChatChannelFormVisibilitySection from "@/apps/list/app/chat/form/sections/ChatChannelFormVisibilitySection"
import AppDescriptionFormSection from "@/apps/list/form-sections/AppDescriptionFormSection"
import AppSubmitFormSection from "@/apps/list/form-sections/AppSubmitFormSection"
import { CreateAppFormState, EditAppFormState } from "@/apps/list/modal/SetupAppModal"
import { useAppLevel } from "@/apps/util/appLevelContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import { useAddStreamChannelToContext } from "@/core/context/StreamChatContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ProductSelect from "@/product/common/ProductSelect"
import { MemberMultiSelect } from "@/product/member/common/ProductMemberMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelBadge from "@components/chat/badge/ChatChannelBadge"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import DiscoBanner from "@disco-ui/banner/DiscoBanner"
import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type ChatChannelMemberMultiSelect = MemberMultiSelect & {
  joinMethod: ChatChannelMemberJoinMethod
}

export type CreateChatChannelAppState = CreateChatChannelAppInput &
  CreateAppFormState & {
    memberships: ChatChannelMemberMultiSelect[]
    /** Product selection */
    productId: GlobalID | null
    memberGroupIds: GlobalID[]
  }

type UpdateChatChannelAppState = UpdateChatChannelAppInput &
  EditAppFormState & {
    memberships: ChatChannelMemberMultiSelect[]
    memberGroupIds: GlobalID[]
    // for display purposes only
    visibility: ChatChannelVisibility
  }

interface Props {
  testid: string
  productLevel?: boolean
  appId?: GlobalID
  onClose: () => void
  onBack?: () => void
}

function ChatChannelSettingsForm({
  testid,
  appId,
  onClose,
  onBack,
  productLevel,
}: Props) {
  const addStreamChannelToContext = useAddStreamChannelToContext()
  const { product, navFolderId, navSectionId } = useAppLevel()
  const history = useHistory()
  const activeOrganization = useActiveOrganization()!
  const productId = product?.id || null
  const labels = useLabels()
  const classes = useStyles()
  const copilotBot = useCopilotBot()

  const { app } = useLazyLoadQuery<ChatChannelSettingsFormQuery>(
    graphql`
      query ChatChannelSettingsFormQuery($appId: ID!) {
        app: node(id: $appId) {
          ... on ProductApp {
            id
            badge {
              ...BadgeFragment @relay(mask: false)
            }
            customAppTitle
            customAppDescription
            showOnDashboard
            ...ChatChannelBadgeFragment
            chatChannel {
              id
              kind
              visibility
              isAutomaticallyJoined
              productId
              productApp {
                id
                ...useRenderProductAppItemFragment @relay(mask: false)
              }
              mgets: memberGroupEntityTargets {
                edges {
                  node {
                    id
                    memberGroupId
                  }
                }
              }
              chatChannelMembers(first: 100) {
                edges {
                  node {
                    id
                    joinMethod
                    productMembershipId
                    organizationMembership {
                      id
                      role
                    }
                    productMembership {
                      role
                    }
                    user {
                      id
                      fullName
                    }
                  }
                }
              }
              botChannelMemberships {
                edges {
                  node {
                    id
                    botId
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      appId: appId || "",
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const isBotActive = copilotBot?.status === "active"

  const createForm = useFormStore<
    ChatChannelSettingsForm_CreateMutation,
    CreateChatChannelAppState
  >(
    graphql`
      mutation ChatChannelSettingsForm_CreateMutation(
        $input: CreateChatChannelAppInput!
        $isOrgTopLevel: Boolean!
      ) {
        response: createChatChannelApp(input: $input) {
          node {
            id
            isAutomaticallyJoined
            app {
              id
              customAppTitle
              customAppDescription
              showOnDashboard
              status
              ordering
              chatChannel {
                externalChannelId
              }
              badge {
                ...BadgeFragment
              }
              ...useRenderProductAppItemFragment @relay(mask: false)
              navSection {
                ...AppsSidebarList_NavSectionFragment
              }
            }
            product {
              slug
              ...MyExperiencesListItemFragment
              chatChannels {
                edges {
                  node {
                    id
                  }
                }
              }
              ...AppsSidebarList_ProductFragment
            }
            organization {
              ...AppsSidebarList_OrganizationFragment @include(if: $isOrgTopLevel)
              ...StreamChatContext_ChannelsFragment
            }
            ...ChatChannelHeaderFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      visibility: "public",
      memberships: [],
      memberGroupIds: [],
      app: {
        customAppTitle: "",
        customAppDescription: "",
        showOnDashboard: true,
        productId,
        organizationId: activeOrganization.id,
        badge: {
          kind: "icon",
          icon: "pound",
          color: "#ffffff00",
        },
        navSectionId,
      },
      productId,
      // Enable copilot by default if the org has a bot
      botIds: isBotActive ? [copilotBot!.id] : [],
    }
  )

  const allChannelMembers = Relay.connectionToArray(app?.chatChannel?.chatChannelMembers)
  const { channelMembers, channelAdmins } = sortChannelMembers()
  const mgets = Relay.connectionToArray(app?.chatChannel?.mgets)
  const botChannelMemberships = Relay.connectionToArray(
    app?.chatChannel?.botChannelMemberships
  )

  const editForm = useFormStore<
    ChatChannelSettingsForm_UpdateMutation,
    UpdateChatChannelAppState
  >(
    graphql`
      mutation ChatChannelSettingsForm_UpdateMutation(
        $input: UpdateChatChannelAppInput!
      ) {
        response: updateChatChannelApp(input: $input) {
          node {
            id
            app {
              badge {
                ...BadgeFragment
              }
            }
            product {
              id
              slug
            }
            ...ChatChannelHeaderFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      chatChannelId: app?.chatChannel?.id || "",
      memberships: channelMembers.map((ccm) => ({
        id: ccm.productMembershipId || ccm.organizationMembership.id,
        memberId: ccm.user.id,
        joinMethod: ccm.joinMethod,
      })),
      memberGroupIds: mgets.map((mget) => mget.memberGroupId!),
      app: {
        customAppTitle: app?.customAppTitle || "",
        customAppDescription: app?.customAppDescription || "",
        showOnDashboard: Boolean(app?.showOnDashboard),
        badge: app?.badge,
      },
      visibility: app?.chatChannel?.visibility || "public",
      botIds: botChannelMemberships.map((bcm) => bcm.botId),
    }
  )

  const form = app ? editForm : createForm

  return (
    <Form
      testid={testid}
      errorInfo={null}
      knownErrorKeys={form.errors.map((e) => e.field)}
      onSubmit={app ? handleEditApp : handleAddApp}
      buttons={
        <AppSubmitFormSection
          form={form}
          mode={app ? "edit" : "add"}
          onClose={onBack || onClose}
          submitButtonText={"Save Changes"}
          cancelButtonText={"Cancel"}
        />
      }
    >
      {!app && productLevel && (
        <DiscoFormControl
          title={`Select ${labels.admin_experience.singular}`}
          sublabel={`Which ${labels.admin_experience.singular} should this channel belong to?`}
          error={Boolean(createForm.errorsByField.productId)}
        >
          <ProductSelect
            value={createForm.state.productId}
            onChange={(pid) => (createForm.state.productId = pid)}
            organizationId={activeOrganization.id}
            hideNonPublic={false}
            // Only show products that the user has permission to create channels in
            viewerPermission={"chat.manage"}
            testid={testid}
          />
        </DiscoFormControl>
      )}

      <DiscoFormControl
        title={"Channel Name"}
        sublabel={
          "Channel name is displayed in the left navigation. It's recommended to keep it brief and concise."
        }
        errorMessages={form.errorsByField.customAppTitle}
        marginBottom={2}
      >
        <DiscoInput
          data-testid={"ChatChannelSettingsForm.channel-name-input"}
          name={"title"}
          value={form.state.app.customAppTitle}
          placeholder={"Channel"}
          startAdornment={
            <div className={classes.container}>
              {app ? (
                <ChatChannelBadge appKey={app} />
              ) : (
                <ChatChannelPreviewBadge
                  productId={createForm.state.productId}
                  badge={createForm.state.app.badge!}
                />
              )}
            </div>
          }
          onChange={handleTitleChange}
          padding={"0 10px 0 0"}
        />

        {getNameSuggestions()}
      </DiscoFormControl>

      <AppDescriptionFormSection
        kind={"chat_channel"}
        value={form.state.app.customAppDescription || ""}
        onChange={(description) => (form.state.app.customAppDescription = description)}
        errorMessages={form.errorsByField.customAppDescription}
      />

      {app?.chatChannel?.visibility === "private" && (
        <DiscoDivider marginTop={0} marginBottom={2} />
      )}

      <ChatChannelFormVisibilitySection
        form={form}
        productLevel={productLevel}
        disabled={Boolean(app)}
      />

      {(createForm.state.visibility === "private" ||
        app?.chatChannel?.visibility === "private") && (
        <DiscoSection
          groovyDepths={"insideCard"}
          padding={1.5}
          marginTop={-1}
          marginLeft={0.25}
          marginRight={0.25}
          marginBottom={2.5}
        >
          <ChatChannelFormGroupsSection
            form={app ? editForm : createForm}
            productId={app?.chatChannel?.productId || createForm.state.productId}
            organizationId={activeOrganization.id}
          />

          <ChatChannelFormMembersSection
            form={form}
            productId={app?.chatChannel?.productId || createForm.state.productId}
            channelAdmins={channelAdmins.map((ccm) => ccm.user)}
          />
        </DiscoSection>
      )}

      {/** Copilot settings */}
      {isBotActive && form.state.visibility === "public" && (
        <DiscoBanner
          testid={"CopilotBotSettingsBanner"}
          variant={"copilot"}
          icon={<AIStarsIcon />}
          message={
            <>
              <DiscoText variant={"body-md-600"}>{`Enable ${
                copilotBot!.name
              }`}</DiscoText>
              <DiscoText>
                {"Receive suggested answers to questions asked in Disco channels."}
              </DiscoText>
            </>
          }
          buttons={
            <DiscoSwitch
              checked={Boolean(form.state.botIds?.length)}
              onChange={handleToggleCopilot}
              label={form.state.botIds?.length ? "On" : "Off"}
            />
          }
        />
      )}
    </Form>
  )

  async function handleAddApp() {
    const canSubmit = validateCanSubmitForm()
    if (!canSubmit) return

    try {
      const { didSave, response } = await createForm.submit(
        {
          visibility: createForm.state.visibility,
          addUserIds: createForm.state.memberships.map((m) => m.memberId),
          addMemberGroupIds: createForm.state.memberGroupIds,
          app: {
            customAppTitle: createForm.state.app.customAppTitle,
            customAppDescription: createForm.state.app.customAppDescription,
            productId: createForm.state.productId,
            navFolderId,
            organizationId: activeOrganization.id,
            badge: createForm.state.app.badge,
            showOnDashboard: createForm.state.app.showOnDashboard,
            navSectionId: createForm.state.app.navSectionId,
          },
          // Don't allow adding bots to private channels
          botIds:
            createForm.state.visibility === "private" ? [] : createForm.state.botIds,
        },
        {
          variables: {
            isOrgTopLevel:
              !createForm.state.productId && !createForm.state.app.navSectionId,
          },
        }
      )
      if (!didSave || !response?.node?.app?.chatChannel?.externalChannelId) return

      addStreamChannelToContext(
        response.node.app?.chatChannel?.externalChannelId,
        app?.chatChannel?.kind === "direct_message"
      )

      displaySuccessToast({
        message: "Channel created!",
        testid: "NewChatChannelForm.success-toast",
      })

      onClose()

      if (response.node.id) {
        redirectToChannel(response.node.id, response.node.product?.slug)
      }
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function validateCanSubmitForm() {
    if (!createForm.state.productId && productLevel) {
      createForm.addError({
        field: "productId",
        message: `Please select a ${labels.admin_experience.singular}`,
      })
      return false
    }

    return true
  }

  async function handleEditApp() {
    // Find the added and removed channel members
    const { added: addUserIds, removed: removeUserIds } = ArrayUtils.diff(
      editForm.initialState.memberships
        .filter((m) => m.joinMethod === "manual")
        .map((m) => m.memberId),
      editForm.state.memberships
        .filter((m) => m.joinMethod === "manual")
        .map((m) => m.memberId)
    )

    const { added: addMemberGroupIds, removed: removeMemberGroupIds } = ArrayUtils.diff(
      editForm.initialState.memberGroupIds,
      editForm.state.memberGroupIds
    )

    try {
      const { didSave, response } = await editForm.submit({
        chatChannelId: app?.chatChannel?.id || "",
        addUserIds,
        removeUserIds,
        addMemberGroupIds,
        removeMemberGroupIds,
        app: {
          customAppTitle: editForm.changedState.app?.customAppTitle,
          customAppDescription: editForm.changedState.app?.customAppDescription,
          badge: {
            kind: editForm.state.app.badge!.kind,
            color: editForm.state.app.badge?.color,
            icon: editForm.state.app.badge?.icon,
            emoji: editForm.state.app.badge?.emoji,
            mediaUrl: editForm.state.app.badge?.mediaUrl,
            assetId: editForm.state.app.badge?.assetId,
          },
          showOnDashboard: editForm.state.app.showOnDashboard,
        },
        botIds: editForm.state.botIds,
      })
      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Channel updated!",
        testid: "EditChannelForm.success-toast",
      })

      onClose()
      if (response.node.id) {
        redirectToChannel(response.node.id, response.node.product?.slug)
      }
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function redirectToChannel(channelId: GlobalID, productSlug?: string | null) {
    // Don't redirect if we're on the chat's page already
    if (location.pathname.includes(channelId)) return
    if (productSlug) {
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.CHAT.CHANNEL, {
          productSlug,
          channelId,
        })
      )
    } else {
      history.push(
        generatePath(ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL, {
          channelId,
        })
      )
    }
  }

  function sortChannelMembers() {
    const members = []
    const admins = []
    for (const ccm of allChannelMembers) {
      // Product role takes precedence if it exists, otherwise use organization role
      if (ccm.productMembership?.role) {
        if (
          ccm.productMembership.role === "manager" ||
          ccm.productMembership.role === "instructor"
        ) {
          admins.push(ccm)
        } else {
          members.push(ccm)
        }
      } else if (
        ccm.organizationMembership.role === "owner" ||
        ccm.organizationMembership.role === "admin"
      ) {
        admins.push(ccm)
      } else {
        members.push(ccm)
      }
    }
    return { channelMembers: members, channelAdmins: admins }
  }

  function handleTitleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (app) {
      editForm.state.app.customAppTitle = e.target.value
      return
    }
    createForm.state.app.customAppTitle = e.target.value
  }

  function getNameSuggestions() {
    if (productLevel || app?.chatChannel?.productId) {
      return (
        <DiscoText variant={"body-sm"} color={"text.secondary"} marginTop={1}>
          {`Eg. A channel name for the ${labels.admin_experience.singular} `}
          <DiscoText variant={"body-sm-600"} display={"inline"}>
            {"Digital Marketing Training"}
          </DiscoText>
          {` could be: `}
          <DiscoText variant={"body-sm-600"} display={"inline"} fontStyle={"italic"}>
            {"DMT General"}
          </DiscoText>
          {`, `}
          <DiscoText variant={"body-sm-600"} display={"inline"} fontStyle={"italic"}>
            {"DMT Introduction"}
          </DiscoText>
          {`, etc.`}
        </DiscoText>
      )
    }

    return (
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginTop={1}>
        {`Eg. A community channel name could be: `}
        <DiscoText variant={"body-sm-600"} display={"inline"} fontStyle={"italic"}>
          {"Community General"}
        </DiscoText>
        {`, `}
        <DiscoText variant={"body-sm-600"} display={"inline"} fontStyle={"italic"}>
          {"Introductions"}
        </DiscoText>
        {`, etc.`}
      </DiscoText>
    )
  }

  function handleToggleCopilot(checked: boolean) {
    if (copilotBot?.status !== "active") return

    if (checked) {
      form.state.botIds = observable.array([copilotBot.id])
    } else {
      form.state.botIds!.replace([])
    }
  }
}

function ChatChannelSettingsFormSkeleton() {
  return (
    <Form
      id={"EditChatChannelForm"}
      testid={"EditChatChannelForm.skeleton"}
      buttons={
        <>
          <DiscoButtonSkeleton width={"80px"} />
          <DiscoButtonSkeleton width={"100px"} />
        </>
      }
    >
      <DiscoFormControl
        title={"Channel Name"}
        sublabel={
          "Channel name is displayed in the left navigation. It's recommended to keep it brief and concise."
        }
      >
        <DiscoInputSkeleton />
      </DiscoFormControl>

      <DiscoFormControl
        title={"Channel Access"}
        sublabel={"Select who will have access to this channel."}
      >
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Skeleton variant={"rect"} width={"100%"} height={"100px"} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton variant={"rect"} width={"100%"} height={"100px"} />
          </Grid>
        </Grid>
      </DiscoFormControl>
    </Form>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: 36,
    height: 40,
    borderRight: `1px solid ${theme.palette.constants.divider}`,
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default Relay.withSkeleton<Props>({
  component: observer(ChatChannelSettingsForm),
  skeleton: ChatChannelSettingsFormSkeleton,
})
