import NavFolderSettingsForm from "@/apps/add-nav-folder-button/NavFolderSettingsForm"
import { NavFolderSettingsButtonFragment$key } from "@/apps/add-nav-folder-button/__generated__/NavFolderSettingsButtonFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { graphql, useFragment } from "react-relay"

interface NavFolderSettingsButtonProps {
  appKey?: NavFolderSettingsButtonFragment$key
  children: OverridableDiscoButtonChildren
}

function NavFolderSettingsButton({ appKey, children }: NavFolderSettingsButtonProps) {
  const classes = useStyles()
  const app = useFragment<NavFolderSettingsButtonFragment$key>(
    graphql`
      fragment NavFolderSettingsButtonFragment on ProductApp {
        id
      }
    `,
    appKey || null
  )

  return (
    <>
      <OverridableDiscoButton
        modal={({ isOpen, onClose }) => {
          if (!isOpen) return null

          return (
            <DiscoModal
              testid={app ? "EditNavFolderButton" : "AddNavFolderButton"}
              modalContentLabel={`${app ? "Edit" : "Create"} Folder`}
              title={`${app ? "Edit" : "Create"} Folder`}
              subtitle={app ? "" : "Folders are used to groups relevant product apps."}
              width={"100%"}
              maxWidth={"480px"}
              isOpen
              onClose={onClose}
              classes={{ headerRow: classes.title }}
              buttons
              body={
                <NavFolderSettingsForm
                  appId={app?.id}
                  isEditing={Boolean(app)}
                  onClose={onClose}
                />
              }
            />
          )
        }}
      >
        {children}
      </OverridableDiscoButton>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1.75),
  },
}))

export default NavFolderSettingsButton
