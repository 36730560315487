import ChatChannelSettingsForm from "@/apps/list/app/chat/form/ChatChannelSettingsForm"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"

interface Props {
  testid: string
  appId?: GlobalID
  isOpen?: boolean
  onClose?: () => void
  productLevel?: boolean
}

function ChatChannelSettingsModal({
  testid,
  appId,
  isOpen,
  onClose,
  productLevel,
}: Props) {
  const { openModalState, closeModal, setOpenModalState } = useActiveAppModal()
  const productLabel = useLabel("admin_experience")

  return (
    <SetupAppModal
      testid={`${testid}.chat_channel`}
      isModalOpen={isOpen || openModalState?.kind === "chat_channel"}
      onClose={onClose}
      title={getTitle()}
      description={
        appId
          ? "Edit the details of the channel."
          : "Create a dedicated conversation channel for members to engage and discuss topics."
      }
      body={
        <ChatChannelSettingsForm
          testid={`${testid}.chat_channel`}
          appId={appId}
          onClose={handleClose}
          onBack={handleBack}
          productLevel={productLevel}
        />
      }
    />
  )

  function handleClose() {
    if (onClose) {
      onClose()
      return
    }
    closeModal()
  }

  function handleBack() {
    if (onClose) {
      onClose()
      return
    }
    setOpenModalState({ kind: "add-app" })
  }

  function getTitle() {
    if (appId) {
      if (productLevel) return `Edit ${productLabel.singular} Channel`
      return "Edit Community Channel"
    }

    if (productLevel) return `Setup ${productLabel.singular} Channel`
    return "Setup Community Channel"
  }
}

export default ChatChannelSettingsModal
