import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, APP_KIND_LABELS } from "@/apps/util/appConstants"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddProductApp from "@/apps/util/hooks/useAddApp"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { useState } from "react"
import { generatePath, useHistory } from "react-router-dom"

interface EventsProductAppProps {
  testid: string
}

function EventsProductApp({ testid }: EventsProductAppProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { closeModal } = useActiveAppModal()
  const { product } = useAppLevel()
  const addProductApp = useAddProductApp()
  const history = useHistory()
  const membersLabel = useLabel("admin_member")

  const EVENTS_PRODUCT_APP_TESTID = `${testid}.events`

  return (
    <AppListItem
      testid={`${testid}.events`}
      title={"Events"}
      description={`Add a collection of events for all ${membersLabel.plural} or specific groups.`}
      icon={APP_KIND_ICON.events}
      onClick={handleAddEventsApp}
      isLoading={isLoading}
    />
  )

  async function handleAddEventsApp() {
    setIsLoading(true)
    try {
      await addProductApp("events", EVENTS_PRODUCT_APP_TESTID, APP_KIND_LABELS.events)
      closeModal()
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT, {
          productSlug: product!.slug,
        })
      )
    } catch (error) {
      displayErrorToast(error)
    }
    setIsLoading(false)
  }
}

export default EventsProductApp
