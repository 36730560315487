import {
  BadgeInput,
  ChatChannelVisibility,
} from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsForm_CreateMutation.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ProductUtils from "@/product/util/productUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoAlert,
  DiscoFormControl,
  DiscoIcon,
  DiscoSelect,
  DiscoText,
} from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<{
    visibility: ChatChannelVisibility
    app?: {
      badge?: BadgeInput | null
    }
  }>
  productLevel?: boolean
  disabled?: boolean
}

function ChatChannelFormVisibilitySection({ form, productLevel, disabled }: Props) {
  const classes = useStyles()
  const labels = useLabels()

  const membersLabel = productLevel
    ? labels.product_member.plural
    : labels.organization_member.plural

  return (
    <DiscoFormControl
      label={<DiscoText variant={"body-md-600"}>{"Channel Access"}</DiscoText>}
      sublabel={"Select who will have access to this channel."}
      testid={"ChatChannelFormFields.visibility"}
    >
      <DiscoAlert
        severity={"warning"}
        message={"This cannot be changed after creating a channel."}
        marginBottom={1.5}
      />
      <DiscoSelect
        value={form.state.visibility}
        testid={"ChatChannelFormFields.visibility.access-select"}
        disabled={disabled}
        disableClearable
        options={[
          {
            value: "public",
            title: `All ${membersLabel}`,
            subtitle: `All ${membersLabel} of the ${
              productLevel ? labels.admin_experience.singular : "Community"
            } can access.`,
            context: {
              icon: ProductUtils.getAvailabilityIcon("public"),
            },
          },
          {
            value: "private",
            title: "Private",
            subtitle: `Only specific ${membersLabel} or groups can access.`,
            context: {
              icon: ProductUtils.getAvailabilityIcon("private"),
            },
          },
        ]}
        renderOption={(option) => (
          <div className={classes.accessOption}>
            <DiscoIcon icon={option.context.icon} color={"currentColor"} />
            <div>
              <DiscoText
                testid={"ChatChannelFormFields.visibility.access-select-option"}
                variant={"body-md-500"}
              >
                {option.title}
              </DiscoText>
              <DiscoText>{option.subtitle}</DiscoText>
            </div>
          </div>
        )}
        onChange={(v) => {
          form.state.visibility = v as ChatChannelVisibility
        }}
      />
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles((theme) => ({
  accessOption: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

export default observer(ChatChannelFormVisibilitySection)
