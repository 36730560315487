/**
 * @generated SignedSource<<b8a4728c3b4a9b22ccce9b80a45f658d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavFolderSettingsButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "NavFolderSettingsButtonFragment";
};
export type NavFolderSettingsButtonFragment$key = {
  readonly " $data"?: NavFolderSettingsButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavFolderSettingsButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavFolderSettingsButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "aca6a618948fbce454136740d4811457";

export default node;
