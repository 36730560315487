import {
  CreateAppFormStore,
  EditAppFormStore,
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import Form from "@components/form/Form"
import { DiscoButton } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  onClose: () => void
  onNext?: () => void
  form:
    | CreateAppFormStore
    | EditAppFormStore
    | LegacyCreateSetupAppModalFormStore
    | LegacyEditSetupAppModalFormStore
  mode: "add" | "edit"
  isSubmitDisabled?: boolean
  submitButtonText?: string
  cancelButtonText?: string
}

function AppSubmitFormSection({
  onClose,
  onNext,
  form,
  mode,
  isSubmitDisabled,
  submitButtonText,
  cancelButtonText,
}: Props) {
  return (
    <>
      <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
        {getCancelButtonText()}
      </DiscoButton>
      {renderSubmitButton()}
    </>
  )

  function renderSubmitButton() {
    if (onNext)
      return (
        <DiscoButton onClick={onNext} testid={"AppSubmitFormSection.nextButton"}>
          {"Next Step"}
        </DiscoButton>
      )

    return (
      <Form.SubmitButton
        form={form}
        shouldDisplaySpinner={getIsSubmitting()}
        disabled={getIsDisabled()}
        testid={"AppSubmitFormSection.submitButton"}
      >
        {getSubmitButtonText()}
      </Form.SubmitButton>
    )
  }

  function getCancelButtonText() {
    if (cancelButtonText) return cancelButtonText

    if (mode === "edit") {
      return "Cancel"
    }
    return "Back to previous"
  }

  function getSubmitButtonText() {
    if (submitButtonText) return submitButtonText

    if (mode === "edit") {
      return "Update App"
    }
    return "Complete Setup"
  }

  function getIsSubmitting() {
    return form.isSubmitting
  }

  function getIsDisabled() {
    if (isSubmitDisabled !== undefined) return isSubmitDisabled
    if (mode === "edit" && !form.isChanged) return true
    return form.isSubmitting
  }
}

export default observer(AppSubmitFormSection)
