import AppListItem from "@/apps/list/AppListItem"
import { AppIconKind, useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, ContentAppKind } from "@/apps/util/appConstants"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"

interface Props {
  testid: string
  kind: ContentAppKind
  entitlement?: Entitlement
}

function ContentApp({ entitlement, testid, kind }: Props) {
  const { setOpenModalState } = useActiveAppModal()

  const appText = getAppListItemProps()

  return (
    <AppListItem
      testid={`${testid}.content`}
      title={appText.title}
      description={appText.description}
      icon={appText.icon}
      onClick={openModal}
      entitlement={entitlement}
    />
  )

  function openModal() {
    setOpenModalState({
      kind: "content",
      contentAppKind: kind,
    })
  }

  function getAppListItemProps(): {
    title: string
    description?: string
    icon: AppIconKind
  } {
    switch (kind) {
      case "page":
        return {
          title: "Content",
          description: "Add content with text, images, videos and embeds.",
          icon: APP_KIND_ICON.page,
        }
      case "custom_embed":
        return {
          title: "Embed",
          description: "Input your custom HTML embed code.",
          icon: APP_KIND_ICON.custom_embed,
        }
      case "airtable":
        return {
          title: "Airtable",
          icon: APP_KIND_ICON.airtable,
        }
      case "discord":
        return {
          title: "Discord",
          icon: APP_KIND_ICON.discord,
        }
      case "google_doc":
        return {
          title: "Google Docs",
          icon: APP_KIND_ICON.google_doc,
        }
      case "google_form":
        return {
          title: "Google Forms",
          icon: APP_KIND_ICON.google_form,
        }
      case "typeform":
        return {
          title: "Typeform",
          icon: APP_KIND_ICON.typeform,
        }
      case "whereby":
        return {
          title: "Whereby",
          icon: APP_KIND_ICON.whereby,
        }
      case "figma":
        return {
          title: "Figma",
          icon: APP_KIND_ICON.figma,
        }
      case "miro":
        return {
          title: "Miro",
          icon: APP_KIND_ICON.miro,
        }
      case "scorm":
        return {
          title: "SCORM",
          icon: APP_KIND_ICON.scorm,
        }
      default:
        throw new Error(`Unkown ContentAppKind: ${kind}`)
    }
  }
}

export default ContentApp
