import CollectionAppSettingsModal from "@/apps/list/app/collections/CollectionAppSettingsModal"
import { CollectionAppSettingsButtonFragment$key } from "@/apps/list/app/collections/__generated__/CollectionAppSettingsButtonFragment.graphql"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { OverridableDiscoButton } from "@disco-ui/button/OverridableDiscoButton"
import { graphql, useFragment } from "react-relay"

interface CollectionAppSettingsButtonProps {
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string

  appKey: CollectionAppSettingsButtonFragment$key
}

const TEST_ID = "CollectionAppSettings"

export default function CollectionAppSettingsButton({
  appKey,
  children,
}: CollectionAppSettingsButtonProps) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<CollectionAppSettingsButtonFragment$key>(
    graphql`
      fragment CollectionAppSettingsButtonFragment on ProductApp {
        id
        ...CollectionAppSettingsModalFragment
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} testid={TEST_ID}>
        {children}
      </OverridableDiscoButton>

      {openModalState?.id === app.id && (
        <CollectionAppSettingsModal testid={TEST_ID} appKey={app} />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({
      kind: "collection",
      id: app.id,
    })
  }
}
