import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"

interface EventsAppProps {
  testid: string
}

function EventsApp({ testid }: EventsAppProps) {
  const { setOpenModalState } = useActiveAppModal()

  return (
    <AppListItem
      testid={`${testid}.events`}
      title={"Events"}
      description={"Host live or in-person events."}
      icon={APP_KIND_ICON.events}
      onClick={handleOpenAddEventsModal}
    />
  )

  function handleOpenAddEventsModal() {
    setOpenModalState({
      kind: "organization_events",
    })
  }
}

export default EventsApp
