import { ProductAppKind } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"

type AppsWithDescription = Extract<ProductAppKind, "posts" | "chat_channel">

interface Props {
  kind: AppsWithDescription
  value: string
  onChange: (description: string) => void
  errorMessages?: string[]
  additionalDescription?: string
}

function AppDescriptionFormSection({
  kind,
  value,
  onChange,
  errorMessages,
  additionalDescription,
}: Props) {
  const classes = useStyles()
  const membersLabel = useLabel("admin_member")

  const labels: Record<AppsWithDescription, string> = {
    posts: "Feed",
    chat_channel: "Channel",
  }
  const label = labels[kind]

  return (
    <DiscoFormControl
      title={`${label} Description`}
      sublabel={`Help ${
        membersLabel.plural
      } understand the types of topics encouraged within this ${label.toLowerCase()}. ${
        additionalDescription || ""
      }`}
      errorMessages={errorMessages}
      marginBottom={2}
    >
      <DiscoInput
        data-testid={"AppDescriptionFormSection.Input.description"}
        className={classes.input}
        name={"description"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={`Describe of the purpose of the ${label.toLowerCase()}...`}
        multiline
        fullWidth
      />
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles({
  input: {
    height: "76px",
    overflowY: "auto",
  },
})

export default observer(AppDescriptionFormSection)
