import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"

interface CollectionAppProps {
  testid: string
}

function CollectionApp({ testid }: CollectionAppProps) {
  const { setOpenModalState } = useActiveAppModal()

  return (
    <AppListItem
      testid={`${testid}.collection`}
      title={"Collection"}
      description={"Create a library of videos, documents or link resources."}
      icon={APP_KIND_ICON.collection}
      onClick={handleOpenAddCollectionModal}
    />
  )

  function handleOpenAddCollectionModal() {
    setOpenModalState({
      kind: "collection",
    })
  }
}

export default CollectionApp
