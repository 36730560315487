import {
  CreateAppFormStore,
  EditAppFormStore,
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { useAppLevel } from "@/apps/util/appLevelContext"
import { useLabel } from "@/core/context/LabelsContext"
import { DiscoFormControl, DiscoSwitch, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form?: CreateAppFormStore | EditAppFormStore
  legacyForm?: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
}

function AppFormDashboardBlockSection({ form, legacyForm }: Props) {
  const { product } = useAppLevel()
  const experienceLabel = useLabel("admin_experience")

  if (!product) return null

  return (
    <DiscoFormControl marginBottom={2}>
      <DiscoSwitch
        testid={`AppFormDashboardBlockSection.show-on-dashboard-toggle`}
        name={"show-on-dashboard-toggle"}
        checked={Boolean(
          form?.state.app
            ? form.state.app.showOnDashboard
            : legacyForm?.state.showOnDashboard
        )}
        onChange={handleChange}
        label={
          <DiscoText
            variant={"body-sm"}
          >{`Include on ${experienceLabel.singular} Dashboard`}</DiscoText>
        }
      />
    </DiscoFormControl>
  )

  function handleChange() {
    if (form?.state.app) {
      form.state.app.showOnDashboard = !form.state.app.showOnDashboard
    } else if (legacyForm?.state) {
      legacyForm.state.showOnDashboard = !legacyForm.state.showOnDashboard
    }
  }
}

export default observer(AppFormDashboardBlockSection)
