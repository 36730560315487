/**
 * @generated SignedSource<<95cb3d382fab7fc2601cfbe1bf99c4bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelPreviewBadgeQuery$variables = {
  id: string;
};
export type ChatChannelPreviewBadgeQuery$data = {
  readonly product: {
    readonly badge?: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    } | null;
  } | null;
};
export type ChatChannelPreviewBadgeQuery = {
  variables: ChatChannelPreviewBadgeQuery$variables;
  response: ChatChannelPreviewBadgeQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatChannelPreviewBadgeQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BadgeFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatChannelPreviewBadgeQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4dab2a50738aa24858f3d0eb615ec950",
    "id": null,
    "metadata": {},
    "name": "ChatChannelPreviewBadgeQuery",
    "operationKind": "query",
    "text": "query ChatChannelPreviewBadgeQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      badge {\n        ...BadgeFragment\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n"
  }
};
})();

(node as any).hash = "9ad62494bdc2aebed62a48579c9402bf";

export default node;
