/**
 * @generated SignedSource<<5dcf1b801a5bdcb6fef50e29a6708683>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionAppSettingsButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionAppSettingsModalFragment">;
  readonly " $fragmentType": "CollectionAppSettingsButtonFragment";
};
export type CollectionAppSettingsButtonFragment$key = {
  readonly " $data"?: CollectionAppSettingsButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionAppSettingsButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionAppSettingsButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionAppSettingsModalFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "27100076089a868713dd7b95b3876ef6";

export default node;
