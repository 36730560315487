import { SlackProductConnectionSettingsModalQuery } from "@/apps/list/app/slack/modal/__generated__/SlackProductConnectionSettingsModalQuery.graphql"
import { SlackProductConnectionSettingsModal_createMutation } from "@/apps/list/app/slack/modal/__generated__/SlackProductConnectionSettingsModal_createMutation.graphql"
import { SlackProductConnectionSettingsModal_editMutation } from "@/apps/list/app/slack/modal/__generated__/SlackProductConnectionSettingsModal_editMutation.graphql"
import SlackProductConnectionSettingsModalForm from "@/apps/list/app/slack/modal/form/SlackProductConnectionSettingsModalForm"
import { useAppLevel } from "@/apps/util/appLevelContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoModal, DiscoText } from "@disco-ui"
import DiscoUnorderedList from "@disco-ui/list/unordered-list/DiscoUnorderedList"
import { Link } from "@material-ui/core"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React from "react"
import { graphql } from "react-relay"

interface SlackProductConnectionSettingsModalProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => Promise<void> | void
}

const SlackProductConnectionSettingsModal = observer(
  ({
    isModalOpen,
    setIsModalOpen,
    onSuccess,
  }: SlackProductConnectionSettingsModalProps) => {
    const { product } = useAppLevel()
    const activeOrganization = useActiveOrganization()!

    const [node, refetch] =
      Relay.useRefetchableQuery<SlackProductConnectionSettingsModalQuery>(
        graphql`
          query SlackProductConnectionSettingsModalQuery(
            $organizationId: ID!
            $productId: ID!
          ) {
            organization: node(id: $organizationId) {
              id
              ... on Organization {
                slack {
                  workspace {
                    name
                  }
                  availableChannels {
                    id
                    name
                    type
                  }
                }
              }
            }
            product: node(id: $productId) {
              id
              ... on Product {
                slack {
                  id
                  inviteURL
                  channel {
                    id
                    name
                    type
                  }
                }
                memberGroups(kind: custom) {
                  totalCount
                }
              }
            }
          }
        `,
        {
          organizationId: activeOrganization.id,
          productId: product?.id || "",
        },
        { fetchPolicy: "network-only" }
      )

    if (!node) return null

    const createForm = useFormStore<SlackProductConnectionSettingsModal_createMutation>(
      graphql`
        mutation SlackProductConnectionSettingsModal_createMutation(
          $input: CreateSlackProductConnectionInput!
        ) {
          response: createSlackProductConnection(input: $input) {
            node {
              id
              inviteURL
              channel {
                id
                name
                type
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        organizationId: activeOrganization.id,
        productId: product?.id || "",
        channelId: null,
        inviteURL: null,
      }
    )

    const editForm = useFormStore<SlackProductConnectionSettingsModal_editMutation>(
      graphql`
        mutation SlackProductConnectionSettingsModal_editMutation(
          $input: EditSlackProductConnectionInput!
        ) {
          response: editSlackProductConnection(input: $input) {
            node {
              inviteURL
              channel {
                id
                name
                type
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        slackProductConnectionId: node.product?.slack?.id || "",
        channelId: node.product?.slack?.channel?.id || null,
        inviteURL: node.product?.slack?.inviteURL || null,
      }
    )

    const form = node.product?.slack?.id ? editForm : createForm
    const classes = useStyles()
    const hasMemberGroups = Boolean(node.product?.memberGroups?.totalCount)

    return (
      <DiscoModal
        title={"Slack"}
        modalContentLabel={"Slack modal"}
        testid={"SlackProductConnectionSettingsModal"}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        buttons
        body={
          <>
            <div className={classes.textContainer}>
              <DiscoText marginBottom={2}>
                {
                  "Select the Slack channel you'd like to send notifications to. The following notifications will be visible to everyone in the channel:"
                }
              </DiscoText>

              <DiscoUnorderedList
                indent={4}
                listItems={[
                  "When an event is about to start",
                  "When event details are changed",
                  "When a Module starts",
                  "When a new Post is published",
                ]}
              />
            </div>

            {hasMemberGroups && (
              <DiscoText setColor={"red"}>
                {
                  "Slack notifications will not work when groups are enabled. Please reach out to "
                }
                <Link
                  href={`mailto:support@disco.co`}
                  target={"_blank"}
                  rel={"noopener"}
                  underline={"none"}
                  data-testid={"SlackProductConnectionSettingsModal.email"}
                >
                  {"support@disco.co"}
                </Link>
                {" if you are interesting in using the Slack integration with groups."}
              </DiscoText>
            )}

            <SlackProductConnectionSettingsModalForm
              form={form}
              node={node}
              handleSubmit={handleSubmit}
              handleSelectSlackChannel={handleSelectSlackChannel}
            />
          </>
        }
      />
    )

    async function handleSubmit() {
      try {
        const { didSave } = node.product?.slack?.id
          ? await editForm.submit({
              ...editForm.changedState,
              slackProductConnectionId: node.product.slack.id,
            })
          : await createForm.submit(createForm.state, { connections: [] })

        if (!didSave) return
        await onSuccess()
        await refetch({
          organizationId: activeOrganization.id,
          productId: product?.id || "",
        })
        displaySuccessToast({
          message: "Slack Connected!",
          testid: "SlackProductConnectionSettingsModal.success-message",
        })
      } catch (error) {
        displayErrorToast(error)
      }
    }

    function handleSelectSlackChannel(channelId: string | null) {
      if (!channelId) return
      runInAction(() => {
        form.state.channelId = channelId
      })
    }
  }
)

const useStyles = makeUseStyles((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(2),
  },
}))

export default SlackProductConnectionSettingsModal
