import { SlackProductConnectionSettingsModalQuery$data } from "@/apps/list/app/slack/modal/__generated__/SlackProductConnectionSettingsModalQuery.graphql"
import { CreateSlackProductConnectionInput } from "@/apps/list/app/slack/modal/__generated__/SlackProductConnectionSettingsModal_createMutation.graphql"
import { EditSlackProductConnectionInput } from "@/apps/list/app/slack/modal/__generated__/SlackProductConnectionSettingsModal_editMutation.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Form from "@components/form/Form"
import {
  DiscoFormControl,
  DiscoInput,
  DiscoLink,
  DiscoSelect,
  DiscoText,
} from "@disco-ui"
import DiscoUnorderedList from "@disco-ui/list/unordered-list/DiscoUnorderedList"
import { observer } from "mobx-react-lite"

interface SlackProductConnectionModalFormProps {
  form: FormStore<CreateSlackProductConnectionInput | EditSlackProductConnectionInput>
  node: SlackProductConnectionSettingsModalQuery$data
  handleSelectSlackChannel: (channelId: string | null) => void
  handleSubmit: () => void
}

const SlackProductConnectionModalForm = observer<SlackProductConnectionModalFormProps>(
  ({ form, node, handleSelectSlackChannel, handleSubmit }) => {
    const classes = useStyles()

    const membersLabel = useLabel("product_member")

    return (
      <Form
        testid={"SlackProductConnectionModalForm"}
        onSubmit={handleSubmit}
        knownErrorKeys={[]}
        errorInfo={null}
        buttons={
          <Form.SubmitButton
            form={form}
            disabled={!form.isChanged || (!form.state.inviteURL && !form.state.channelId)}
            shouldDisplaySpinner={form.isSubmitting}
          >
            {"Save"}
          </Form.SubmitButton>
        }
      >
        <div className={classes.workspaceNameContainer}>
          <DiscoText>{"Slack Workspace:"}</DiscoText>
          <DiscoText
            className={classes.workspaceNameText}
            marginLeft={1}
            padding={1}
            testid={"SlackProductConnectionModalForm.workspace-name-text"}
          >
            {node.organization?.slack?.workspace?.name || "No workspace connected"}
          </DiscoText>
        </div>
        <div className={classes.instructionsContainer}>
          <DiscoText marginBottom={1}>
            {`Add a Slack Invite URL. This will create a curriculum item that encourages ${membersLabel.plural} to join your Slack workspace (recommended).`}
            <DiscoLink
              to={
                "https://slack.com/intl/en-ca/help/articles/201330256-Invite-new-members-to-your-workspace#share-an-invitation-link"
              }
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              {"More info"}
            </DiscoLink>
          </DiscoText>

          <div className={classes.selectionContainer}>
            <DiscoFormControl
              errorMessages={form.errorsByField.inviteUrl}
              label={"Slack Invite URL"}
              description={
                <>
                  <DiscoText>{"Where do I find the Slack Invite URL?"}</DiscoText>
                  <DiscoUnorderedList
                    indent={4}
                    customClassNames={{ root: classes.toolTip, text: classes.toolTip }}
                    listItems={[
                      "Navigate to your Slack workspace",
                      "Click your logo in upper right and select “invite people” and click 'Edit Link settings'",
                      "Select the dropdown and change the Expiration date to Never Expires and click Save.",
                      "Click the Copy invite link and paste the link into the input box above.",
                    ]}
                  />
                  <DiscoText marginTop={1}>
                    {"What should my invite URL look like?"}
                  </DiscoText>
                  <DiscoUnorderedList
                    indent={4}
                    customClassNames={{ root: classes.toolTip, text: classes.toolTip }}
                    listItems={[
                      "The URL should be of the form https://join.slack.com/t/your-workspace/shared_invite/",
                    ]}
                  />
                </>
              }
            >
              <DiscoInput
                inputProps={{
                  "data-testid": "SlackProductConnectionModalForm.invite-url.input",
                }}
                name={"url"}
                value={form.state.inviteURL}
                error={Boolean(
                  form.state.inviteURL?.length &&
                    !(
                      form.state.inviteURL?.startsWith("http://") ||
                      form.state.inviteURL?.startsWith("https://")
                    )
                )}
                placeholder={"Enter Slack Invite URL"}
                onChange={(e) =>
                  e.target.value
                    ? (form.state.inviteURL = e.target.value)
                    : (form.state.inviteURL = null)
                }
              />
            </DiscoFormControl>
          </div>
        </div>
        <div className={classes.instructionsContainer}>
          <DiscoText marginBottom={1}>
            {
              "Please select a Slack channel to connect. Currently, only one channel may be connected at a time."
            }
          </DiscoText>

          <div className={classes.selectionContainer}>
            <DiscoFormControl errorMessages={form.errorsByField.channelId}>
              <DiscoSelect
                testid={"SlackProductConnectionModalForm.select-slack-channel"}
                placeholder={"Enter or select a channel name"}
                value={form.state.channelId || node.product?.slack?.channel?.id}
                onChange={(channelId) => handleSelectSlackChannel(channelId)}
                disableClearable
                options={
                  node.organization?.slack?.availableChannels?.map((channel) => ({
                    value: channel.id,
                    title: `#${channel.name}`,
                  })) || []
                }
                filterOptions={{
                  // Show the first 50 available channels
                  maxVisible: 50,
                }}
              />
            </DiscoFormControl>
          </div>
        </div>
      </Form>
    )
  }
)

const useStyles = makeUseStyles((theme) => ({
  workspaceNameContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: `${theme.spacing(3)}px`,
  },
  workspaceNameText: {
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
  },
  toolTip: {
    color: theme.palette.common.white,
  },
  instructionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectionContainer: {
    minWidth: "100%",
    paddingTop: theme.spacing(2),
  },
}))

export default SlackProductConnectionModalForm
