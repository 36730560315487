import { MembersAppSettingsButtonFragment$key } from "@/apps/list/app/members/button/__generated__/MembersAppSettingsButtonFragment.graphql"
import MembersSettingsModal from "@/apps/list/app/members/modal/MembersSettingsModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { OverridableDiscoButton } from "@disco-ui/button/OverridableDiscoButton"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  appKey: MembersAppSettingsButtonFragment$key
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string
}

const TEST_ID = "MembersAppSettingsButton"

function MembersAppSettingsButton({ appKey, children }: Props) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<MembersAppSettingsButtonFragment$key>(
    graphql`
      fragment MembersAppSettingsButtonFragment on ProductApp {
        id
        ...MembersSettingsModalFragment
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>

      {openModalState?.id === app.id && (
        <MembersSettingsModal testid={TEST_ID} appKey={app} />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({ kind: "members", id: app.id })
  }
}

export default MembersAppSettingsButton
