import ChatChannelSettingsDrawerContent from "@/apps/list/app/chat/ChatChannelSettingsDrawerContent"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoDrawer } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
interface ChatChannelSettingsDrawerProps extends TestIDProps {
  appId: GlobalID
  isOpen: boolean
  onClose: VoidFunction
}

function ChatChannelSettingsDrawer({
  appId,
  isOpen,
  onClose,
}: ChatChannelSettingsDrawerProps) {
  return (
    <DiscoDrawer open={isOpen} onClose={onClose}>
      <ChatChannelSettingsDrawerContent appId={appId} onClose={onClose} />
    </DiscoDrawer>
  )
}

export default ChatChannelSettingsDrawer
