import FeedAppSetupModalContent from "@/apps/list/app/feed/modal/FeedAppSetupModalContent"
import { FeedAppSetupModalFragment$key } from "@/apps/list/app/feed/modal/__generated__/FeedAppSetupModalFragment.graphql"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  appKey?: FeedAppSetupModalFragment$key
}

function FeedAppSetupModal({ testid, appKey }: Props) {
  const { openModalState } = useActiveAppModal()

  const app = useFragment<FeedAppSetupModalFragment$key>(
    graphql`
      fragment FeedAppSetupModalFragment on ProductApp {
        id
      }
    `,
    appKey || null
  )

  return (
    <SetupAppModal
      testid={`${testid}.feed`}
      isModalOpen={openModalState?.kind === "posts"}
      title={"Feed App Setup"}
      description={"Create a series of posts for sharing announcements and news."}
      body={<FeedAppSetupModalContent testid={testid} appId={app?.id} />}
    />
  )
}

export default FeedAppSetupModal
