import AppIcon from "@/apps/list/app/AppIcon"
import { AppIconKind } from "@/apps/util/activeAppModalContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PaidFeatureTooltip from "@/organization/entitlements/PaidFeatureTooltip"
import DiscoCard from "@disco-ui/card/DiscoCard"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import React from "react"

interface AppListItemProps {
  testid: string
  title: string
  description?: string
  icon: AppIconKind
  isLoading?: boolean
  entitlement?: Entitlement
  onClick: (() => void) | React.MouseEventHandler<Element>
}

function AppListItem({
  testid,
  title,
  description,
  icon,
  entitlement,
  onClick,
  isLoading,
}: AppListItemProps) {
  const classes = useStyles()
  const hasEntitlement = useHasEntitlement(entitlement)

  return (
    <PaidFeatureTooltip entitlement={entitlement}>
      <DiscoCard
        variant={"compact"}
        testid={testid}
        title={title}
        description={description}
        disabled={isLoading || (entitlement && !hasEntitlement)}
        icon={<div className={classes.iconContainer}>{<AppIcon kind={icon} />}</div>}
        onClick={onClick}
      />
    </PaidFeatureTooltip>
  )
}

const useStyles = makeUseStyles((theme) => ({
  iconContainer: {
    width: "32px",
    height: "32px",
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[200],
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      color: theme.palette.groovy.neutral[700],
      height: "20px",
      width: "20px",
    },
  },
}))

interface SkeletonProps {
  includeDescription?: boolean
}

export const AppListItemSkeleton = ({ includeDescription }: SkeletonProps) => {
  const theme = useTheme()

  return (
    <Skeleton
      variant={"rect"}
      style={{
        borderRadius: theme.measure.borderRadius.medium,
        height: includeDescription ? "120px" : "72px",
        width: "211px",
      }}
    />
  )
}

export default AppListItem
