import { SetupAppModalSkeleton } from "@/apps/list/modal/SetupAppModal"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface NavFolderSettingsFormProps {
  appId?: GlobalID
  isEditing: boolean
  onClose: () => void
}

function NavFolderSettingsForm({
  appId,
  isEditing,
  onClose,
}: NavFolderSettingsFormProps) {
  const classes = useStyles()
  const createForm = useAddAppFormStore({
    kind: "nav_folder",
    customAppTitle: "",
    badge: {
      kind: "icon",
      icon: "folder",
      color: "#ffffff00",
    },
  })

  const editForm = useEditAppFormStore(appId)

  const form = appId ? editForm : createForm

  return (
    <Form
      id={"NavFolderButtonForm"}
      testid={"NavFolderSettingsForm.form"}
      onSubmit={appId ? handleEditApp : handleAddApp}
      buttons={
        <Form.SubmitButton
          id={"NavFolderButtonForm"}
          testid={"NavFolderButtonForm.submit"}
          form={form}
        >
          {`${isEditing ? "Edit" : "Create"} Folder`}
        </Form.SubmitButton>
      }
      classes={{ buttonsRoot: classes.buttonContainer }}
    >
      <DiscoFormControl title={"Folder Title"} classes={{ root: classes.label }}>
        <DiscoInput
          data-testid={"NavFolderButtonForm.Input.app-name"}
          placeholder={"Folder Name"}
          value={form.state.customAppTitle}
          fullWidth
          onChange={(e) => {
            form.state.customAppTitle = e.target.value
          }}
        />
      </DiscoFormControl>
    </Form>
  )

  async function handleAddApp() {
    const { didSave } = await createForm.submit(
      {
        customAppTitle: createForm.state.customAppTitle,
        productId: createForm.state.productId,
        organizationId: createForm.state.organizationId,
        kind: createForm.state.kind,
        badge: createForm.state.badge,
      },
      { connections: [] }
    )
    if (!didSave) return

    displaySuccessToast({
      message: "Folder created!",
      testid: "NavFolderSettingsButton.create.success-message",
    })

    onClose()
  }

  async function handleEditApp() {
    const {
      embedCode: _embedCode,
      scormFileId: _scormFileId,
      ...changedState
    } = editForm.changedState
    const { didSave } = await editForm.submit({
      id: editForm.state.id,
      ...changedState,
      badge: editForm.state.badge,
    })
    if (!didSave) return

    displaySuccessToast({
      message: "Folder updated!",
      testid: "NavFolderSettingsButton.edit.success-message",
    })

    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  label: {
    marginTop: theme.spacing(1.5),
    gap: theme.spacing(0.5),
  },
  buttonContainer: {
    marginTop: 0,
  },
}))

export default Relay.withSkeleton({
  component: observer(NavFolderSettingsForm),
  skeleton: () => <SetupAppModalSkeleton />,
})
