/**
 * @generated SignedSource<<1dca1c2968e92c2328338ea5b675cc03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SlackProductConnectionSlackChannelType = "private" | "public" | "%future added value";
export type SlackProductConnectionSettingsModalQuery$variables = {
  organizationId: string;
  productId: string;
};
export type SlackProductConnectionSettingsModalQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly slack?: {
      readonly workspace: {
        readonly name: string | null;
      } | null;
      readonly availableChannels: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly type: SlackProductConnectionSlackChannelType;
      }> | null;
    } | null;
  } | null;
  readonly product: {
    readonly id: string;
    readonly slack?: {
      readonly id: string;
      readonly inviteURL: string | null;
      readonly channel: {
        readonly id: string;
        readonly name: string;
        readonly type: SlackProductConnectionSlackChannelType;
      } | null;
    } | null;
    readonly memberGroups?: {
      readonly totalCount: number;
    };
  } | null;
};
export type SlackProductConnectionSettingsModalQuery = {
  variables: SlackProductConnectionSettingsModalQuery$variables;
  response: SlackProductConnectionSettingsModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SlackWorkspace",
  "kind": "LinkedField",
  "name": "workspace",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SlackChannel",
  "kind": "LinkedField",
  "name": "availableChannels",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SlackProductConnection",
      "kind": "LinkedField",
      "name": "slack",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inviteURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SlackChannel",
          "kind": "LinkedField",
          "name": "channel",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "custom"
        }
      ],
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "memberGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "memberGroups(kind:\"custom\")"
    }
  ],
  "type": "Product",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SlackProductConnectionSettingsModalQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SlackConnection",
                "kind": "LinkedField",
                "name": "slack",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SlackProductConnectionSettingsModalQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SlackConnection",
                "kind": "LinkedField",
                "name": "slack",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f435d2c5c345a00ddc4a956d49c8baba",
    "id": null,
    "metadata": {},
    "name": "SlackProductConnectionSettingsModalQuery",
    "operationKind": "query",
    "text": "query SlackProductConnectionSettingsModalQuery(\n  $organizationId: ID!\n  $productId: ID!\n) {\n  organization: node(id: $organizationId) {\n    __typename\n    id\n    ... on Organization {\n      slack {\n        workspace {\n          name\n        }\n        availableChannels {\n          id\n          name\n          type\n        }\n        id\n      }\n    }\n  }\n  product: node(id: $productId) {\n    __typename\n    id\n    ... on Product {\n      slack {\n        id\n        inviteURL\n        channel {\n          id\n          name\n          type\n        }\n      }\n      memberGroups(kind: custom) {\n        totalCount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac931dcb925c9841a5f533f517037eb4";

export default node;
