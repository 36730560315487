import LinkAppSetupModal from "@/apps/list/app/link/modal/LinkAppSetupModal"
import { LinkAppSettingsButtonFragment$key } from "@/apps/list/app/link/settings-button/__generated__/LinkAppSettingsButtonFragment.graphql"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { OverridableDiscoButton } from "@disco-ui/button/OverridableDiscoButton"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  appKey: LinkAppSettingsButtonFragment$key
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string
}

const TEST_ID = "LinkAppSettingsButton"

function LinkAppSettingsButton({ appKey, children }: Props) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<LinkAppSettingsButtonFragment$key>(
    graphql`
      fragment LinkAppSettingsButtonFragment on ProductApp {
        id
        ...LinkAppSetupModalFragment
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} testid={TEST_ID}>
        {children}
      </OverridableDiscoButton>

      {openModalState?.id === app.id && (
        <LinkAppSetupModal testid={"AddAppModal"} appKey={app} />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({
      kind: "link",
      id: app.id,
    })
  }
}

export default LinkAppSettingsButton
