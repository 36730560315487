import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddProductApp from "@/apps/util/hooks/useAddApp"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { displayErrorToast } from "@components/toast/ToastProvider"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { useState } from "react"
import { generatePath, useHistory } from "react-router-dom"

interface CurriculumProductAppProps {
  testid: string
}

function CurriculumProductApp({ testid }: CurriculumProductAppProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { closeModal } = useActiveAppModal()
  const { product } = useAppLevel()
  const addProductApp = useAddProductApp()
  const history = useHistory()
  const curriculumLabel = useLabel("curriculum")

  const { curriculumGeneration } = useFeatureFlags()

  const CURRICULUM_PRODUCT_APP_TESTID = `${testid}.curriculum`

  return (
    <AppListItem
      testid={`${testid}.curriculum`}
      title={curriculumLabel.singular}
      description={"Deliver content with modules of content and assignments."}
      icon={APP_KIND_ICON.curriculum}
      onClick={handleAddCurriculumApp}
      isLoading={isLoading}
    />
  )

  async function handleAddCurriculumApp() {
    setIsLoading(true)
    try {
      await addProductApp(
        "curriculum",
        CURRICULUM_PRODUCT_APP_TESTID,
        undefined,
        curriculumGeneration
      )
      closeModal()
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
          productSlug: product!.slug,
        })
      )
    } catch (error) {
      displayErrorToast(error)
    }
    setIsLoading(false)
  }
}

export default CurriculumProductApp
