import { DeleteChatChannelAppButtonFragment$key } from "@/apps/list/app/chat/button/__generated__/DeleteChatChannelAppButtonFragment.graphql"
import { DeleteChatChannelAppButtonMutation } from "@/apps/list/app/chat/button/__generated__/DeleteChatChannelAppButtonMutation.graphql"
import AppUtils from "@/apps/util/AppUtils"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { useFragment, useMutation } from "react-relay"
import { generatePath, useHistory } from "react-router"
import { ConnectionHandler, graphql } from "relay-runtime"

type Props = {
  children: OverridableDiscoButtonChildren
  appKey: DeleteChatChannelAppButtonFragment$key
}

const DeleteChatChannelAppButton: React.FC<Props> = ({ children, appKey }) => {
  const history = useHistory()
  const [isModalOpen, setConfirmationModal] = useState<boolean>(false)

  const app = useFragment<DeleteChatChannelAppButtonFragment$key>(
    graphql`
      fragment DeleteChatChannelAppButtonFragment on ProductApp {
        id
        navSectionId
        product {
          id
          slug
        }
        chatChannel {
          id
          organizationId
        }
      }
    `,
    appKey
  )

  const [commit, isDeleting] = useMutation<DeleteChatChannelAppButtonMutation>(graphql`
    mutation DeleteChatChannelAppButtonMutation(
      $id: ID!
      $appConnections: [ID!]!
      $channelConnections: [ID!]!
    ) {
      response: deleteChatChannelApp(id: $id) {
        deletedChatChannelAppId @deleteEdge(connections: $appConnections) @deleteRecord
        deletedChatChannelId @deleteEdge(connections: $channelConnections) @deleteRecord
        organization {
          ...StreamChatContext_ChannelsFragment
        }
      }
    }
  `)

  return (
    <>
      <OverridableDiscoButton onClick={openConfirmationModal}>
        {children}
      </OverridableDiscoButton>
      <DiscoWarningModal
        testid={"DeleteChatChannelConfirmationModal"}
        isOpen={isModalOpen}
        onClose={() => setConfirmationModal(false)}
        title={"Are you sure you want to permanently delete this channel?"}
        description={
          "This action is irreversible. You will not be able to recover this channel or its message history."
        }
        confirmationButtonProps={{
          onClick: deleteChatChannelApp,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete this channel",
        }}
        modalContentLabel={"Delete Channel Dialog"}
      />
    </>
  )

  function openConfirmationModal() {
    setConfirmationModal(true)
  }

  function deleteChatChannelApp() {
    if (!app.chatChannel) return
    commit({
      variables: {
        id: app.id,
        appConnections: AppUtils.getAppConnectionIds(
          app.chatChannel.organizationId,
          app.product?.id,
          app.navSectionId
        ),
        channelConnections: [
          ConnectionHandler.getConnectionID(
            app.chatChannel.organizationId,
            "ChannelsDashboardBlock__chatChannels"
          ),
        ],
      },
      onCompleted() {
        displayToast({ message: "Successfully deleted channel." })
        setConfirmationModal(false)
      },
      onError() {
        displayErrorToast("An error occurred while trying to delete channel.")
      },
    })

    // Only redirect if we are on the chat channel page
    // NOTE: This must be done outside of the mutation or an error will be thrown
    // "You can't use a channel after client.disconnect() was called"
    if (location.pathname.includes(app.chatChannel!.id)) {
      if (app.product?.slug) {
        history.replace(
          generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: app.product?.slug,
          })
        )
      } else {
        history.replace(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
      }
    }
  }
}

export default DeleteChatChannelAppButton
