import AppsListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import { useLabel } from "@/core/context/LabelsContext"

interface LinkAppProps {
  testid: string
}

function LinkApp({ testid }: LinkAppProps) {
  const { setOpenModalState } = useActiveAppModal()
  const productLabel = useLabel("admin_experience")

  return (
    <>
      <AppsListItem
        testid={`${testid}.link`}
        title={"Link"}
        description={`Add an external link, or link to a ${productLabel.singular}.`}
        icon={APP_KIND_ICON.link}
        onClick={openModal}
      />
    </>
  )

  function openModal() {
    setOpenModalState({ kind: "link" })
  }
}

export default LinkApp
