import { LegacyCreateSetupAppModalFormStore } from "@/apps/list/modal/SetupAppModal"
import AddContentUsageContentOptions, {
  SelectedTab,
} from "@/content-usage/buttons/add-button/AddContentUsageContentOptions"
import { ContentTemplateKind, CONTENT_TEMPLATES } from "@/content/util/contentTemplates"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"

interface ContentAppSelectContentProps extends TestIDProps {
  form: LegacyCreateSetupAppModalFormStore
  handleAddApp: (opts?: { contentTemplate: ContentTemplateKind }) => void
}

function ContentAppSelectContent({ form, handleAddApp }: ContentAppSelectContentProps) {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>("new")

  return (
    <AddContentUsageContentOptions
      addingTo={"product_app"}
      onTemplateSelect={onTemplateSelect}
      onContentSelected={onContentSelected}
      disabled={form.isSubmitting}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      hideAIOption
    />
  )

  function onTemplateSelect(contentTemplate: ContentTemplateKind) {
    const template = CONTENT_TEMPLATES[contentTemplate]
    form.state.contentType = template.contentType
    handleAddApp({ contentTemplate })
  }

  function onContentSelected(contentId: GlobalID) {
    form.state.contentId = contentId
    handleAddApp()
  }
}

export default observer(ContentAppSelectContent)
