import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, APP_KIND_LABELS } from "@/apps/util/appConstants"

interface FeedAppProps {
  testid: string
}

function FeedApp({ testid }: FeedAppProps) {
  const { setOpenModalState } = useActiveAppModal()

  return (
    <AppListItem
      testid={`${testid}.feed`}
      title={APP_KIND_LABELS.posts}
      description={"Create a series of posts. Great for Announcements and news."}
      icon={APP_KIND_ICON.posts}
      onClick={openModal}
    />
  )

  function openModal() {
    setOpenModalState({
      kind: "posts",
    })
  }
}

export default FeedApp
