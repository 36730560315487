import Badge from "@/admin/experiences/badges/Badge"
import { ChatChannelPreviewBadgeQuery } from "@/apps/list/app/chat/form/__generated__/ChatChannelPreviewBadgeQuery.graphql"
import { BadgeInput } from "@/apps/list/app/chat/form/__generated__/ChatChannelSettingsForm_CreateMutation.graphql"
import { ObservableState } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconKinds } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface ChatChannelPreviewBadgeProps extends TestIDProps {
  badge: ObservableState<BadgeInput>
  productId?: GlobalID | null
}

function ChatChannelPreviewBadge({ productId, badge }: ChatChannelPreviewBadgeProps) {
  const { product } = useLazyLoadQuery<ChatChannelPreviewBadgeQuery>(
    graphql`
      query ChatChannelPreviewBadgeQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            badge {
              ...BadgeFragment
            }
          }
        }
      }
    `,
    {
      id: productId || "",
    }
  )

  const classes = useStyles()

  return (
    <div style={{ position: "relative" }}>
      <div className={classes.previewContainer}>{getBadge()}</div>
      {product?.badge && (
        <div className={classes.productBadgeWrapper}>
          <Badge badgeKey={product.badge} className={classes.productBadge} />
        </div>
      )}
    </div>
  )

  function getBadge() {
    if (!badge?.icon) return null
    return <DiscoIcon icon={badge.icon as DiscoIconKinds} />
  }
}

const useStyles = makeUseStyles((theme) => ({
  previewContainer: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.default,
    "& > svg": {
      color: `${theme.palette.common.black} !important`,
    },
  },
  productBadgeWrapper: {
    position: "absolute",
    bottom: 2,
    right: 2,
    padding: 1,
    height: 10,
    width: 10,
    backgroundColor: theme.palette.background.paper,
  },
  productBadge: {
    borderRadius: theme.measure.borderRadius.small,
    height: "10px !important",
    width: "10px !important",
    fontSize: "8px",
    "& > svg": {
      height: "10px",
      width: "10px",
    },
  },
}))

function ChatChannelPreviewBadgeSkeleton() {
  return <Skeleton width={24} height={20} />
}

export default Relay.withSkeleton({
  component: observer(ChatChannelPreviewBadge),
  skeleton: ChatChannelPreviewBadgeSkeleton,
})
