import SlackProductConnectionSettingsModal from "@/apps/list/app/slack/modal/SlackProductConnectionSettingsModal"
import { DiscoButton } from "@disco-ui"
import React, { useState } from "react"

interface SlackProductConnectionSettingsButtonProps {
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string
}

function SlackProductConnectionSettingsButton({
  children,
  ...props
}: SlackProductConnectionSettingsButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      {React.isValidElement(children) ? (
        <DiscoButton onClick={() => setIsModalOpen(true)} {...props}>
          {children}
        </DiscoButton>
      ) : (
        React.createElement(children, { onClick: () => setIsModalOpen(true) })
      )}

      {isModalOpen && (
        <SlackProductConnectionSettingsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onSuccess={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default SlackProductConnectionSettingsButton
