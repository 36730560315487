import {
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { useAppLevel } from "@/apps/util/appLevelContext"
import { ProductAppVisibility } from "@/apps/util/hooks/__generated__/useAddAppMutation.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import ProductUtils from "@/product/util/productUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoIcon, DiscoSelect, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface AppVisibilityFormProps extends TestIDProps {
  form: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
  alert?: React.ReactNode
  disabled?: boolean
}

function AppVisibilityForm({
  testid = "AppVisibilityForm",
  form,
  alert,
  disabled = false,
}: AppVisibilityFormProps) {
  const { product } = useAppLevel()
  const labels = useLabels()
  const memberLabel = product
    ? labels.product_member.plural
    : labels.organization_member.plural
  const classes = useStyles()

  return (
    <DiscoFormControl
      label={<DiscoText variant={"body-sm"}>{"Who can access?"}</DiscoText>}
      errorMessages={form.errorsByField.visibility}
    >
      {alert && <>{alert}</>}
      <DiscoSelect
        value={form.state.visibility}
        testid={`${testid}.access-select`}
        disableClearable
        disabled={disabled}
        options={[
          {
            value: "all",
            title: `All ${memberLabel}`,
            subtitle: `All ${memberLabel} of the ${
              product ? labels.admin_experience.singular : "Community"
            } can access.`,
            context: {
              icon: ProductUtils.getAvailabilityIcon("public"),
            },
          },
          {
            value: "membership",
            title: "Private",
            subtitle: `Only specific ${memberLabel} or groups can access.`,
            context: {
              icon: ProductUtils.getAvailabilityIcon("private"),
            },
          },
        ]}
        renderOption={(option) => (
          <div className={classes.accessOption}>
            <DiscoIcon icon={option.context.icon} color={"currentColor"} />
            <div>
              <DiscoText
                testid={`${testid}.access-select-option`}
                variant={"body-md-500"}
              >
                {option.title}
              </DiscoText>
              <DiscoText>{option.subtitle}</DiscoText>
            </div>
          </div>
        )}
        onChange={(v) => {
          form.state.visibility = v as ProductAppVisibility
        }}
      />
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles((theme) => ({
  accessOption: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

export default observer(AppVisibilityForm)
