import { GenericAppSettingsModalFragment$key } from "@/apps/list/app/common/settings/__generated__/GenericAppSettingsModalFragment.graphql"
import GenericAppSettingsModalContent from "@/apps/list/app/common/settings/GenericAppSettingsModalContent"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  appKey: GenericAppSettingsModalFragment$key
}

function GenericAppSettingsModal({ testid, appKey }: Props) {
  const { openModalState } = useActiveAppModal()

  const app = useFragment<GenericAppSettingsModalFragment$key>(
    graphql`
      fragment GenericAppSettingsModalFragment on ProductApp {
        id
        kind
      }
    `,
    appKey
  )

  return (
    <SetupAppModal
      testid={`${testid}.generic`}
      isModalOpen={openModalState?.kind === "posts"}
      title={"Edit App Settings"}
      description={"Customize the way this app is displayed in the navigation."}
      body={<GenericAppSettingsModalContent appId={app.id} kind={app.kind} />}
    />
  )
}

export default GenericAppSettingsModal
