import ContentAppSetupModal from "@/apps/list/app/content/modal/ContentAppSetupModal"
import { ContentAppSettingsButtonFragment$key } from "@/apps/list/app/content/settings-button/__generated__/ContentAppSettingsButtonFragment.graphql"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { OverridableDiscoButton } from "@disco-ui/button/OverridableDiscoButton"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  appKey: ContentAppSettingsButtonFragment$key
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>): void
      }>
    | string
}

const TEST_ID = "ContentAppSettingsButton"

function ContentAppSettingsButton({ appKey, children }: Props) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  const app = useFragment<ContentAppSettingsButtonFragment$key>(
    graphql`
      fragment ContentAppSettingsButtonFragment on ProductApp {
        id
        ...ContentAppSetupModalFragment
      }
    `,
    appKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} testid={TEST_ID}>
        {children}
      </OverridableDiscoButton>

      {openModalState?.id === app.id && (
        <ContentAppSetupModal testid={TEST_ID} appKey={app} />
      )}
    </>
  )

  function openModal() {
    setOpenModalState({
      kind: "content",
      id: app.id,
    })
  }
}

export default ContentAppSettingsButton
