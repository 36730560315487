import CollectionSettingsForm from "@/apps/list/app/collections/CollectionSettingsForm"
import { CollectionAppSettingsModalFragment$key } from "@/apps/list/app/collections/__generated__/CollectionAppSettingsModalFragment.graphql"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  appKey?: CollectionAppSettingsModalFragment$key
}

function CollectionAppSetupModal({ testid, appKey }: Props) {
  const { openModalState, closeModal, setOpenModalState } = useActiveAppModal()

  const app = useFragment<CollectionAppSettingsModalFragment$key>(
    graphql`
      fragment CollectionAppSettingsModalFragment on ProductApp {
        id
      }
    `,
    appKey || null
  )

  return (
    <SetupAppModal
      testid={`${testid}.feed`}
      isModalOpen={openModalState?.kind === "collection"}
      title={"Collection App Setup"}
      description={
        app
          ? "Edit the icon and title to customize the appearance of your collection app."
          : "Create a series of videos, documents or link resources for easy access."
      }
      body={
        <CollectionSettingsForm
          testid={`${testid}.collection`}
          appId={app?.id}
          onClose={closeModal}
          onBack={app ? closeModal : () => setOpenModalState({ kind: "add-app" })}
        />
      }
    />
  )
}

export default observer(CollectionAppSetupModal)
